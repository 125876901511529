<template>
  <div  class="markets featured-live-stream">
    <div v-if="allFeatured.length > 0" v-animate-css="'zoomIn'" class="featured-heading">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-video"><polygon points="23 7 16 12 23 17 23 7"></polygon><rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect></svg>
        <h6 style="font-family:'Kanit';display:inline-block;margin-left:10px;padding-right:5px;position:relative;top:2px">LIVE</h6>
    </div>
    <carousel v-if="allFeatured.length > 0" v-animate-css="'fadeInDown'" class="featured-carousel featured-carousel-xl" :autoplay="true" :dots="false" :responsiveClass="true" :responsive="{1600:{items:6,nav:true}, 1001:{items:5,nav:true}, 800:{items:4,nav:true}}" :navText="['<','>']">
        <div v-for="(feature, index) in allFeatured" :key="'feature-' + index" class="featured-shop">
            <router-link :to="{ name: 'Shop', params: { id: feature.ShopID } }">
                <div @click="showPopupReel(index)" class="featured-shop-wrapper">
                    <div class="featured-content">
                        <div v-bind:style="{ backgroundImage: 'url(' + feature.Banner + ')' }" class="featured-image"></div>
                        <div class="fader"></div>
                        <div class="featured-detail">
                            <div class="featured-shop-title">{{feature.Title}}</div>
                            <!--<div class="featured-shop-category">{{feature.CategoryNameTH}}</div>-->
                        </div>
                    </div>
                </div>
            </router-link>
        </div>
    </carousel>
    <carousel v-if="allFeatured.length > 0" v-animate-css="'fadeInDown'" class="featured-carousel featured-carousel-l"  :autoplay="true" :dots="false" :responsiveClass="true" :responsive="{1400:{items:4,nav:true},700:{items:3,nav:true},1001:{items:5,nav:true},0:{items:2,nav:true}}" :navText="['<','>']">
        <div v-for="(feature, index) in allFeatured" :key="'feature-' + index" class="featured-shop">
            <router-link :to="{ name: 'Shop', params: { id: feature.ShopID } }">
                <div @click="showPopupReel(index)" class="featured-shop-wrapper">
                    <div class="featured-content">
                        <div v-bind:style="{ backgroundImage: 'url(' + feature.Banner + ')' }" class="featured-image"></div>
                        <div class="fader"></div>
                        <div class="featured-detail">
                            <div class="featured-shop-title">{{feature.Title}}</div>
                            <!--<div class="featured-shop-category">{{feature.Description}}</div>-->
                        </div>
                    </div>
                </div>
            </router-link>
        </div>
    </carousel>
    <div v-bind:class="showpopup" class="popup-reel">
        <div class="reel-backdrop"></div>
        <div @click="hidePopupReel" class="reel-close">
            <svg fill="white" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 512 512"><path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4 L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1 c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1 c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"></path></svg>
        </div>
        <!--<div class="reel-player"><iframe width="480" height="100%" src="https://www.youtube.com/embed/igSmBBjZ71o?autoplay=1&loop=1" title="Just when you want to be at one with the universe, the sky graciously opens in Lampang." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe></div>-->
        <div class="reel-player"><video autoplay="autoplay" controls="controls" id="reel" loop="true" muted width="480" height="100%" src="https://assets.mixkit.co/videos/preview/mixkit-two-avenues-with-many-cars-traveling-at-night-34562-large.mp4"></video></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import carousel from 'vue-owl-carousel'

export default {
    name: "Featured",
    components:{
        carousel 
    },
    data() {
        return {
            showpopup: '',
        };
    },
    methods:{
        ...mapActions(["getFeaturedStream"]),
        showPopupReel: function(){
            this.showpopup = 'active';
        },
        hidePopupReel: function(){
            this.showpopup = '';
        }
    },
    computed: mapGetters(["allFeatured"]),
    created() {
        //this.getFeaturedStream()
    }
}
</script>

<style>
    .featured-carousel-l{
        display:none;
    }
    .featured-live-stream{
        margin-left: 25px;
        margin-right: auto;
        width:98%;
        padding-top: 20px;
    }
    .featured-carousel{
        animation-duration: 0.1s;
        animation-delay: 0.1s;
    }
    .featured-shop{
        padding: 0px 10px 10px 0px;
    }
    .featured-shop-wrapper{
        overflow: hidden;
        border-radius: 8px;
    }
    .featured-image{
        padding-bottom: 120%;
        background-size: cover;
        background-position: center center;
        border-radius: 8px;
        transition: all 0.4s ease;
    }
    .featured-shop-wrapper:hover .featured-image{
        transform: scale(1.2);
    }
    .featured-detail{
        position: absolute;
        bottom: 40px;
        left: 25px;
        right: 25px;
        color: #fff;
        width:82%;
        word-wrap:break-word;
        transition: all 0.4s ease;
        font-family: 'Kanit';
    }
    .featured-shop-wrapper:hover .featured-detail{
        bottom: 55px;
    }
    .fader{
        content: "";
        background-image: -webkit-linear-gradient(transparent,rgba(0,0,0,0.2) 50%,rgba(0,0,0,0.7));
        background-image: linear-gradient(transparent,rgba(0,0,0,0.2) 50%,rgba(0,0,0,0.7));
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        bottom: 10px;
        left: 0px;
        right: 10px;
        border-radius: 8px;
        transition: all 0.2s ease;
    }
    .featured-shop-title{
        font-weight: bold;
        font-size:20px;
    }
    .featured-shop-category{
        color:rgba(255,255,255,0.6)
    }
    .featured-shop-category:hover{
        color:rgba(255,255,255,1);
        text-decoration:underline
    }
    .owl-prev {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 40%;
        left: 20px;
        display: block !important;
        border:0px solid black;
        font-weight: bold;
        font-size:20px !important;
        background-color: rgba(0,0,0,0.5) !important;
        transition: all 0.2s ease;
    }
    .owl-prev:hover{
        background-color: rgba(0,0,0,1) !important;
    }
    .owl-next {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 40%;
        right: 20px;
        display: block !important;
        border:0px solid black;
        background-color: rgba(0,0,0,0.5);
        font-weight: bold;
        font-size:20px !important;
        background-color: rgba(0,0,0,0.5) !important;
        transition: all 0.2s ease;
    }
    .owl-prev i, .owl-next i {
        transform : scale(1,6); color: #ccc;
    }
    .featured-heading {
        padding: 7px 10px;
        background-color: #f3155b;
        border-radius: 6px;
        color: #fff;
        position: relative;
        top: 25px;
        left: 10px;
        z-index: 10;
        width:auto;
        width:93px;
        display: none;
    }
    .featured-icon{
        display:inline-block;
    }
    .popup-reel{
        display: none;
    }
    .popup-reel.active{
        display: block;
    }
    .popup-reel{
        position:fixed;
        top:0px;
        left:0px;
        background-color:rgba(0, 0, 0, 0.85);
        width:100%;
        height:100%;
        z-index: 99;
    }
    .reel-player{
        width:480px;
        height:96vh;
        background-color:rgb(10, 10, 10);
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
        margin-top:2vh;
    }
    .reel-close {
        background-color: #4a4a4a;
        border: none;
        cursor: pointer;
        outline: none;
        position: fixed;
        top: 0;
        right: 0;
        vertical-align: bottom;
        height: 65px;
        width: 60px;
        padding: 15px 12px 15px 20px;
        border-bottom-left-radius: 50%;
    }
    @media only screen and (max-width: 1600px) {
        .featured-carousel-xl{
            display: none;
        }
        .featured-carousel-l{
            display: block;
        }
    }
    @media only screen and (max-width: 1100px) {
        .featured-carousel {
            margin-top:-20px;
        }
        .featured-heading {
            top:40px;
        }
        .featured-live-stream{
            width:95%;
        }
        .featured-carousel-xl{
            display: none;
        }
        .featured-carousel-l{
            display: block;
        }
    }
    @media only screen and (max-width: 900px) {
        .featured-detail{
            left:10px
        }
    }
    @media only screen and (max-width: 600px) {
        .featured-shop-title{
            font-size: 16px;
        }
        .reel-player{
            width:100%;
        }
        #reel{
            width:100%;
            height:70vh;
            margin-top:17vh;
        }
        .reel-close{
            margin-top:65px;
        }

    }
    @media only screen and (min-width: 1001px) and (max-width: 1300px) {
        .featured-detail{
            left: auto;
        }
        .featured-shop-title{
            font-size: 14px;
        }
    }
</style>
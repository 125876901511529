<template>
  <div class="shop-container">
    <div class="cart-mobile-wrapper">
      <router-link :to="'/cart/'+this.$route.params.id">
        <img style="height:25px;margin-right:15px" src="../assets/shopping-cart.svg" />
        <div v-if="cartamount > 0" class="cart-amount mobile">{{cartamount}}</div>
      </router-link>
    </div>
    <div class="shop-wrapper shop-detail">
      <div class="main-content">
        <div class="shop-live-mobile">
          <!--<iframe width="100%" height="200" src="https://www.youtube.com/embed/IIzHiKZ8c6g?autoplay=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
          <div
            style="margin-top: 20px; margin-bottom: 15px"
            class="shop-section"
          >
            <div class="section-label">
              Shop
              <div class="shop-location">{{ shopInfo.Location }}</div>
            </div>
            <div class="section-detail">{{ shopInfo.Name }}</div>
          </div>
        </div>
        <audio style="display: none" id="notiSound">
          <source src="../assets/noti.mp3" type="audio/mpeg" />
        </audio>
        <div class="shop-section top-section mobile-shop-info">
          <div
            class="shop-logo"
            v-bind:style="{
              'background-image': 'url(' + shopInfo.Logo + ')',
            }"
          ></div>
          <div class="shop-side-description">{{ shopInfo.Description }}</div>
          <div class="shop-location-info">
            {{ shopInfo.Name }} - {{ shopInfo.Location }}<br />
          </div>
          <div v-if="shopInfo.Facebook || shopInfo.Line || shopInfo.Phone" class="social-media-wrapper contact">
            <div
              v-if="
                shopInfo.Facebook && shopInfo.Facebook.includes('facebook.com')
              "
              class="social-platform"
            >
              <a target="_blank" v-bind:href="shopInfo.Facebook"
                ><img src="../assets/Facebook.png"
              /></a>
            </div>
            <div
              v-if="
                shopInfo.Facebook && !shopInfo.Facebook.includes('facebook.com')
              "
              class="social-platform"
            >
              <a
                target="_blank"
                v-bind:href="'https://www.facebook.com/' + shopInfo.Facebook"
                ><img src="../assets/Facebook.png"
              /></a>
            </div>
            <!-- <div v-if="shopInfo.Instagram" class="social-platform">
              <a
                target="_blank"
                v-bind:href="'https://www.instagram.com/' + shopInfo.Instagram"
                ><img src="../assets/Instagram.png"
              /></a>
            </div> -->
            <div
              v-if="shopInfo.Line.includes('@') && shopInfo.Line"
              class="social-platform"
            >
              <a
                target="_blank"
                v-bind:href="'https://line.me/R/ti/p/' + shopInfo.Line"
                ><img src="../assets/LINE.png"
              /></a>
            </div>
            <div
              v-if="!shopInfo.Line.includes('@') && shopInfo.Line"
              class="social-platform"
            >
              <a
                target="_blank"
                v-bind:href="'https://line.me/ti/p/~' + shopInfo.Line"
                ><img src="../assets/LINE.png"
              /></a>
            </div>
            <!--<div v-if="shopInfo.Twitter" class="social-platform">
              <a
                target="_blank"
                v-bind:href="'https://twitter.com/' + shopInfo.Twitter"
                ><img src="../assets/Twitter.png"
              /></a>
            </div>-->
            <div v-if="shopInfo.Phone" class="social-platform">
              <a target="_blank" v-bind:href="'tel:' + shopInfo.Phone"
                ><img src="../assets/telephone.png"
              /></a>
            </div>
          </div>
        </div>
        <div class="filter-group">
          <!-- <div class="filter-item filter-button">Most Likes</div>
            <div class="filter-item filter-button">Most Viewed</div>
            <div class="filter-item filter-button">Breakfast</div>
            <div class="filter-item filter-button">Lunch</div>
            <div class="filter-item filter-button">Dinner</div>
            <div class="filter-item filter-button">Dessert</div>
            <div class="filter-item filter-button">Hottest Deal</div> -->
          <div
            v-for="(category, index) in allShopCategories"
            :key="category.id"
            v-on:click="setShopCategory(index, category.CategoryID)"
            class="shop-filter-item"
            :class="category.status"
          >
            <span v-if="category.CategoryID == 0">ทั้งหมด</span>
            <span v-if="category.CategoryID == 2">แฟชั่นผู้ชาย</span>
            <span v-if="category.CategoryID == 1">แฟชั่นผู้หญิง</span>
            <span v-if="category.CategoryID == 3">เด็ก</span>
            <span v-if="category.CategoryID == 4">เครื่องประดับ</span>
            <span v-if="category.CategoryID == 5">อุปกรณ์ อิเล็กทรอนิกส์</span>
            <span v-if="category.CategoryID == 6">อุปกรณ์ ประดับรถยนต์</span>
            <span v-if="category.CategoryID == 7">สุขภาพและความงาม</span>
            <span v-if="category.CategoryID == 8">อาหาร</span>
            <span v-if="category.CategoryID == 9">ขนมหวาน</span>
            <span v-if="category.CategoryID == 10">เครื่องดื่ม</span>
            <span v-if="category.CategoryID == 11">สัตว์เลี้ยง</span>
            <span v-if="category.CategoryID == 12">อื่น ๆ</span>
          </div>
        </div>
        <vue-easy-lightbox
          moveDisabled
          :visible="visible"
          :imgs="imgs"
          :index="index"
          @hide="handleHide"
        ></vue-easy-lightbox>
        <masonry
          v-bind:class="{ locked: shopSideBarState == 'show-shopside-menu' }"
          class="shop-post-container"
          :cols="{ default: 3, 1600: 2, 1100:3 ,850: 2, 600: 1 }"
          :gutter="{ default: '25px', 1000: '15px', 700: '0px' }"
        >
          <div v-for="post in allShopPosts" :key="post.id">
            <div
              :id="post.notiID"
              v-bind:class="{ isrotate: post.isRotate }"
              class="post-wrapper card"
            >
              <div class="front">
                <div class="post-top-section">
                  <!-- <div
                    class="shop-logo"
                    v-bind:style="{
                      'background-image': 'url(' + post.Logo + ')',
                    }"
                  ></div>
                  <div v-if="post.Name" class="shop-name">{{ post.Name }}</div>
                  <div v-else class="shop-name">-</div>
                  <div class="post-date">
                    <font-awesome-icon
                      class="post-icon"
                      :icon="['far', 'clock']"
                    />
                    <span style="margin-left: 5px; margin-top: -2px">{{
                      post.updated_at | moment("subtract", "3 hours", "MMMM Do")
                    }}</span>
                  </div>-->
                  <div class="clearfix"></div>
                  <div
                    v-if="post.Banner.split('/')[4] == 'images'"
                    class="post-image"
                  >
                    <img v-bind:src="post.Preview" />
                    <div
                      v-on:click="showSingle(post.Banner, post.Description)"
                      class="post-view"
                    >
                      <font-awesome-icon class="view-icon" icon="eye" />
                    </div>
                  </div>
                  <div v-else class="post-video">
                    <!-- <video style="width:100%;" controls :src="post.Banner" class="view--video__video"></video> -->
                    <VideoPlayer :videoURL="post.Banner"></VideoPlayer>
                  </div>
                                    <div
                    v-on:click="setShopCategorybyID(0)"
                    v-if="post.CategoryID == 0"
                    class="footer-item footer-category"
                  >
                    ทั้งหมด
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(2)"
                    v-if="post.CategoryID == 2"
                    class="footer-item footer-category"
                  >
                    แฟชั่นผู้ชาย
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(1)"
                    v-if="post.CategoryID == 1"
                    class="footer-item footer-category"
                  >
                    แฟชั่นผู้หญิง
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(3)"
                    v-if="post.CategoryID == 3"
                    class="footer-item footer-category"
                  >
                    เด็ก
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(4)"
                    v-if="post.CategoryID == 4"
                    class="footer-item footer-category"
                  >
                    เครื่องประดับ
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(5)"
                    v-if="post.CategoryID == 5"
                    class="footer-item footer-category"
                  >
                    อุปกรณ์ อิเล็กทรอนิกส์
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(6)"
                    v-if="post.CategoryID == 6"
                    class="footer-item footer-category"
                  >
                    อุปกรณ์ ประดับรถยนต์
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(7)"
                    v-if="post.CategoryID == 7"
                    class="footer-item footer-category"
                  >
                    สุขภาพและความงาม
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(8)"
                    v-if="post.CategoryID == 8"
                    class="footer-item footer-category"
                  >
                    อาหาร
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(9)"
                    v-if="post.CategoryID == 9"
                    class="footer-item footer-category"
                  >
                    ขนมหวาน
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(10)"
                    v-if="post.CategoryID == 10"
                    class="footer-item footer-category"
                  >
                    เครื่องดื่ม
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(11)"
                    v-if="post.CategoryID == 11"
                    class="footer-item footer-category"
                  >
                    สัตว์เลี้ยง
                  </div>
                  <div
                    v-on:click="setShopCategorybyID(11)"
                    v-if="post.CategoryID == 12"
                    class="footer-item footer-category"
                  >
                    อื่น ๆ
                  </div>
                  <!-- <div
                 v-if="Number(post.PriceTill).toLocaleString() !== '0' && post.PriceFrom != post.PriceTill"
                 style="float: right"
                 class="footer-item footer-date"
               >
                 <svg
                   style="margin-right: 10px; margin-top: -3px"
                   xmlns="http://www.w3.org/2000/svg"
                   width="16"
                   height="16"
                   viewBox="0 0 24 24"
                   fill="none"
                   stroke="#ec7465"
                   stroke-width="2"
                   stroke-linecap="round"
                   stroke-linejoin="round"
                   class="feather feather-shopping-bag"
                 >
                   <path
                     d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
                   ></path>
                   <line x1="3" y1="6" x2="21" y2="6"></line>
                   <path d="M16 10a4 4 0 0 1-8 0"></path>
                 </svg>
                 <span
                   >{{ Number(post.PriceFrom).toLocaleString() }} -
                   {{ Number(post.PriceTill).toLocaleString() }} บาท</span
                 >
               </div>
               <div
                 v-else
                 style="float: right"
                 class="footer-item footer-date"
               >
                <div v-if="Number(post.PriceFrom) != 0">
                 <svg
                   style="margin-right: 10px; margin-top: -3px"
                   xmlns="http://www.w3.org/2000/svg"
                   width="16"
                   height="16"
                   viewBox="0 0 24 24"
                   fill="none"
                   stroke="#ec7465"
                   stroke-width="2"
                   stroke-linecap="round"
                   stroke-linejoin="round"
                   class="feather feather-shopping-bag"
                 >
                   <path
                     d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
                   ></path>
                   <line x1="3" y1="6" x2="21" y2="6"></line>
                   <path d="M16 10a4 4 0 0 1-8 0"></path>
                 </svg>
                 <span
                   >{{ Number(post.PriceFrom).toLocaleString() }} บาท</span
                 >
                </div>-->
                <div
                 style="float: right"
                 class="footer-item footer-date"
               >
                <div v-if="Number(post.PriceTill) != 0">
                 <svg
                   style="margin-right: 10px; margin-top: -3px"
                   xmlns="http://www.w3.org/2000/svg"
                   width="16"
                   height="16"
                   viewBox="0 0 24 24"
                   fill="none"
                   stroke="#ec7465"
                   stroke-width="2"
                   stroke-linecap="round"
                   stroke-linejoin="round"
                   class="feather feather-shopping-bag"
                 >
                   <path
                     d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
                   ></path>
                   <line x1="3" y1="6" x2="21" y2="6"></line>
                   <path d="M16 10a4 4 0 0 1-8 0"></path>
                 </svg>
                 <span
                   >{{ Number(post.PriceTill).toLocaleString() }} บาท</span
                 >
                </div>
               </div>
                  <div class="clearfix"></div>
                  <div class="post-title">{{ post.Title }}</div>
                  <div class="post-desc">{{ post.Description }}</div>
                </div>
                <div class="post-bottom-section">
                  <div class="post-footer">
                  <!--<div class="post-sku">{{ post.SKU }}</div>-->
                  <!-- <div class="read-more">Read More</div> -->
                    <!--<div
                      v-on:click="
                        setChatProduct(post.id, post.Banner, post.Title, post);
                        post.isRotate = true;
                      "
                      class="chat-icon"
                    >-->
                      <!--<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#f3155b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>-->
                      <!--<div class="from-them">
                        <span>คุยกับร้านค้า . . .</span>
                      </div>
                      <div v-if="post.unread > 0" class="unread-noti">
                        {{ post.unread }}
                      </div>
                    </div>-->
                    <div class="post-shop-location">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="#3c5b68" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                    กรุงเทพฯ-สายใต้เซ็นเตอร์
                  </div>
                    <!--<div class="view-wrapper">
                      <div
                        v-if="Number(post.PriceTill).toLocaleString() !== '0'"
                        style="float: right"
                        class="footer-item footer-date"
                      >
                        <svg
                          style="margin-right: 10px; margin-top: -3px"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#ec7465"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-shopping-bag"
                        >
                          <path
                            d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
                          ></path>
                          <line x1="3" y1="6" x2="21" y2="6"></line>
                          <path d="M16 10a4 4 0 0 1-8 0"></path>
                        </svg>
                        <span
                          >{{ Number(post.PriceFrom).toLocaleString() }} -
                          {{ Number(post.PriceTill).toLocaleString() }} บาท</span
                        >
                      </div>
                      <div
                        v-else
                        style="float: right"
                        class="footer-item footer-date"
                      >
                        <svg
                          style="margin-right: 10px; margin-top: -3px"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#ec7465"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-shopping-bag"
                        >
                          <path
                            d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
                          ></path>
                          <line x1="3" y1="6" x2="21" y2="6"></line>
                          <path d="M16 10a4 4 0 0 1-8 0"></path>
                        </svg>
                        <span
                          >{{ Number(post.PriceFrom).toLocaleString() }} บาท</span
                        >
                      </div>
                    </div>-->
                    <div class="view-wrapper">
                      <div v-if="post.amount == 0" class="addtocart-wrapper" v-on:click="doAddtoCart(post.id)">
                        <svg style="margin-right:5px;margin-top:-3px;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#196a9f" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>
                        เพิ่มลงตะกร้า
                      </div>
                      <!--<div v-else class="cart-amount">{{post.amount}}</div>-->
                      <div v-else class="cart-quantity-wrapper">
                        <span style="color:#196a9f;padding-right:9px;">Qty:</span>
                        <select @change="quantityChange(post.id,post.amount)" v-model="post.amount" class="cart-quantity"> 
                          <option>0</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                          <option>9</option>
                        </select>
                      </div>
                    </div>
                  <div class="clearfix"></div>
                </div>
                </div>
              </div>
              <div class="back">
                <div class="product-chat">
                  <svg
                    style="cursor: pointer"
                    v-on:click="closeProductChat(post)"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#f3155b"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevron-left flipback"
                  >
                    <polyline points="15 18 9 12 15 6"></polyline>
                  </svg>
                  <div v-if="post.Banner.split('/')[4] == 'images'">
                    <div
                      class="circular"
                      v-bind:style="{
                        backgroundImage: 'url(' + post.Banner + ')',
                      }"
                    ></div>
                  </div>
                  <div v-else class="post-video-circular">
                    <VideoPlayer :videoURL="chatproductImage"></VideoPlayer>
                  </div>
                  <div v-if="post.Title !== ''" class="chat-product-name">
                    {{ post.Title }}
                  </div>
                  <div v-else class="chat-product-name">-</div>
                </div>
                <div id="post-chatbox" class="product-chat-log">
                  <div
                    v-bind:class="msg.sender"
                    v-mod
                    v-for="msg in post.chats"
                    v-bind:key="msg.id"
                    class="chat-msg"
                  >
                    <div
                      v-if="msg.sender == 'Customer'"
                      class="bubble-speech bubble-right"
                    >
                      <div class="message">
                        {{ msg.text }}
                      </div>
                    </div>
                    <div v-else class="bubble-speech bubble-left">
                      <div class="message">
                        {{ msg.text }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="chat-box">
                  <input
                    v-model="userChatmsg"
                    @keyup.enter="sendChat()"
                    placeholder="ส่งข้อความแชท"
                  />
                  <div v-on:click="sendChat()" class="send-product-chat">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#ffffff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-send"
                    >
                      <line x1="22" y1="2" x2="11" y2="13"></line>
                      <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </masonry>
      </div>
      <div class="sidebar-right">
        <!-- <div class="shop-section shop-video">
          <div class="shop-intro"><img v-bind:src="shopInfo.Logo"/></div>
        </div> -->
        <!--<div class="shop-section">
          <div class="section-label">LIVE</div>
          <iframe width="240" height="200" src="https://www.youtube.com/embed/IIzHiKZ8c6g?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>-->
        <!--
        <div class="shop-section">
          <div class="section-label">Shop</div>
          <div class="section-detail">{{shopInfo.Name}}</div>
        </div>
        <div class="shop-section">
          <div class="section-label">Location</div>
          <div class="section-detail">{{shopInfo.Location}}</div>
        </div>
        <div class="shop-section">
          <div class="section-label">About</div>
          <div class="section-detail">{{shopInfo.Description}}</div>
        </div>-->
        <!--<div class="shop-section">
          <div class="section-label">Social Media</div>
          <div class="section-detail">
            <div class="social-media-wrapper">
              <div class="social-platform"><a target="_blank" v-bind:href='"https://www.facebook.com/"+shopInfo.Facebook'><img src="../assets/Facebook.png"/></a></div>
              <div class="social-platform"><a target="_blank" v-bind:href='"https://www.instagram.com/"+shopInfo.Instagram'><img src="../assets/Instagram.png"/></a></div>
              <div class="social-platform"><a target="_blank" v-bind:href='"https://line.me/R/ti/p/"+shopInfo.Line'><img src="../assets/LINE.png"/></a></div>
              <div class="social-platform"><a target="_blank" v-bind:href='"https://twitter.com/"+shopInfo.Twitter'><img src="../assets/Twitter.png"/></a></div>
            </div>
          </div>
        </div>-->
        <!--<div class="chat-heads">
          <div
            v-on:click="setChatProduct(null, '', '')"
            v-bind:style="{ 'background-image': 'url(' + shopInfo.Logo + ')' }"
            class="current-shop-logo"
          ></div>
          <div
            v-if="currentShopUnread > 0"
            class="current-shop-noti-circular"
          ></div>
          <div class="chat-other-shops">
            <vuescroll :ops="ops">
              <div v-for="(chatshop, index) in chatShops" :key="chatshop.id">
                <div v-if="chatshop.Status == '0'" class="chat-head-wrapper">
                  <div v-if="chatshop.unread > 0" class="noti-circular"></div>
                  <div
                    v-on:click="closeChat(chatshop.id, index)"
                    class="close-chathead"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#f3155b"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-x"
                    >
                      <line x1="18" y1="6" x2="6" y2="18"></line>
                      <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                  </div>
                  <router-link :to="'/shop/' + chatshop.ShopID">
                    <div
                      v-tooltip.top-center="chatshop.ShopName"
                      v-bind:style="{
                        'background-image': 'url(' + chatshop.Logo + ')',
                      }"
                      class="chat-shop-logo"
                    ></div>
                  </router-link>
                </div>
              </div>
            </vuescroll>
          </div>
        </div>-->
        <div class="chat-box-wrapper">
          <div class="shop-section top-section">
            <router-link :to="'/cart/'+this.$route.params.id">
            <div class="cart">
                <svg style="margin-right:10px;margin-top:-2px" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#3893c1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>
                {{cartamount}} Items in Cart
            </div>
            </router-link>
            <div class="section-label">เกี่ยวกับร้านค้า</div>
            {{ shopInfo.Name }} - {{ shopInfo.Location }}<br />
            <span v-if="shopInfo.Phone">เบอร์ติดต่อ: {{ shopInfo.Phone }}</span>
            <div class="shop-side-description">{{ shopInfo.Description }}</div>
          </div>
          <div class="chat-header">ติดต่อร้าน</div>
          <div v-if="shopInfo.Facebook || shopInfo.Line || shopInfo.Phone" class="social-media-wrapper contact">
            <div
              v-if="
                shopInfo.Facebook && shopInfo.Facebook.includes('facebook.com')
              "
              class="social-platform"
            >
              <a target="_blank" v-bind:href="shopInfo.Facebook"
                ><img src="../assets/Facebook.png"
              /></a>
              <div class="social-label">Facebook</div>
            </div>
            <div
              v-if="
                shopInfo.Facebook && !shopInfo.Facebook.includes('facebook.com')
              "
              class="social-platform"
            >
              <a
                target="_blank"
                v-bind:href="'https://www.m.me/' + shopInfo.Facebook"
                ><img src="../assets/messenger_icon.png"
              /></a>
              <div class="social-label">Facebook</div>
            </div>
            <!-- <div v-if="shopInfo.Instagram" class="social-platform">
              <a
                target="_blank"
                v-bind:href="'https://www.instagram.com/' + shopInfo.Instagram"
                ><img src="../assets/Instagram.png"
              /></a>
              <div class="social-label">Instagram</div>
            </div> -->
            <div
              v-if="shopInfo.Line.includes('@') && shopInfo.Line"
              class="social-platform"
            >
              <a
                target="_blank"
                v-bind:href="'https://line.me/R/ti/p/' + shopInfo.Line"
                ><img src="../assets/LINE.png"
              /></a>
              <div class="social-label">LINE</div>
            </div>
            <div
              v-if="!shopInfo.Line.includes('@') && shopInfo.Line"
              class="social-platform"
            >
              <a
                target="_blank"
                v-bind:href="'https://line.me/ti/p/~' + shopInfo.Line"
                ><img src="../assets/LINE.png"
              /></a>
              <div class="social-label">LINE</div>
            </div>
            <!-- <div v-if="shopInfo.Twitter" class="social-platform">
              <a
                target="_blank"
                v-bind:href="'https://twitter.com/' + shopInfo.Twitter"
                ><img src="../assets/Twitter.png"
              /></a>
              <div class="social-label">Twitter</div>
            </div> -->
            <div v-if="shopInfo.Phone" class="social-platform">
              <a target="_blank" v-bind:href="'tel:' + shopInfo.Phone"
                ><img src="../assets/telephone.png"
              /></a>
              <div class="social-label">โทร</div>
            </div>
          </div>
          <!-- <div v-if="chatproductImage == ''" class="chat-header">
            SHOP CHAT
          </div> -->
          <!--<div v-else class="chat-header">
            <div v-if="chatproductImage.split('/')[4] == 'images'" class="post-image">
                <img v-bind:src="chatproductImage"/>
            </div>
            <div v-else class="post-video">
              <VideoPlayer :videoURL='chatproductImage'></VideoPlayer>
            </div>
            <div class="chat-product-name">
              {{chatproductName}}
            </div>
          </div>
          <div id="stream-chatbox" class="chat-body">
            <div v-if="chatmsgs.length < 1" class="chat-welcome">Welcome to the chat</div>
            <div v-else v-bind:class="msg.sender" v-mod v-for="msg in chatmsgs" v-bind:key="msg.id" class="chat-msg">
              <div v-if="msg.sender == 'Customer'" class="bubble-speech bubble-right">
                <div class="message">
                  {{msg.text}}
                </div>
              </div>
              <div v-else class="bubble-speech bubble-left">
                <div class="message">
                  {{msg.text}}
                </div>
              </div>
            </div>
          </div>
          <div class="chat-input">
            <input @focus="checkUserStatus()" v-model="usermsg" @keyup.enter="sendChat()" placeholder="Send a Message"/>
            <div v-if="userInfo.id" class="send-chat">
              <div v-on:click="sendChat()" class="chatbtt">Chat</div>
              <div v-bind:class="chatSettingClass" class="chat-setting">
                <div class="setting-header">ตั้งค่าการแชท</div>
                <font-awesome-icon v-on:click="toggleChatSetting()" class="close-icon" icon="times" />
                <div class="chat-setting-header">
                  ชื่อที่แสดงของคุณ
                </div>
                <input v-model="chatname"/>
                <div v-on:click="signOut()" class="signout">ออกจากระบบ</div>
              </div>
              <font-awesome-icon v-on:click="toggleChatSetting()" class="chat-setting-icon" icon="cog" />
            </div>
            <div v-else>
              <div v-on:click="goLogin()" class="signinbtt">เข้าสู่ระบบ</div>
            </div>
          </div>-->
        </div>
        <div class="clearfix"></div>
        <div class="map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3874.9778614629217!2d100.42222841534728!3d13.780208100345982!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e2974c3c370c61%3A0x50529146dc9c63!2sSai%20Tai%20Mai%20(Talingchan)%20Bus%20Terminal!5e0!3m2!1sen!2sth!4v1622630768848!5m2!1sen!2sth" width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
      </div>
    </div>
    <div
      v-on:click="closeAllMenu()"
      v-bind:class="shopSideBarState"
      class="menu-overlay"
    >
      Tycoon
    </div>
    <div v-bind:class="shopSideBarState" class="sidebar-mobile-right">
      <!-- <div class="shop-section shop-video">
        <div class="shop-intro"><img v-bind:src="shopInfo.Logo"/></div>
      </div>
      <div class="shop-section">
        <div class="section-label">About</div>
        <div class="section-detail">{{shopInfo.Description}}</div>
      </div>
      <div class="shop-section">
        <div class="section-label">Social Media</div>
        <div class="section-detail">
          <div class="social-media-wrapper">
              <div class="social-platform"><a target="_blank" v-bind:href='"https://www.facebook.com/"+shopInfo.Facebook'><img src="../assets/Facebook.png"/></a></div>
              <div class="social-platform"><a target="_blank" v-bind:href='"https://www.instagram.com/"+shopInfo.Instagram'><img src="../assets/Instagram.png"/></a></div>
              <div class="social-platform"><a target="_blank" v-bind:href='"https://line.me/R/ti/p/"+shopInfo.Line'><img src="../assets/LINE.png"/></a></div>
              <div class="social-platform"><a target="_blank" v-bind:href='"https://twitter.com/"+shopInfo.Twitter'><img src="../assets/Twitter.png"/></a></div>
          </div>
        </div>
      </div> -->
      <div class="chat-heads">
        <div
          v-on:click="setChatProduct(null, '', '')"
          v-bind:style="{ 'background-image': 'url(' + shopInfo.Logo + ')' }"
          class="current-shop-logo"
        ></div>
        <div
          v-if="currentShopUnread > 0"
          class="current-shop-noti-circular"
        ></div>
        <div class="chat-other-shops">
          <vuescroll :ops="ops">
            <div v-for="(chatshop, index) in chatShops" :key="chatshop.id">
              <!-- {{chatshop.id}} -->
              <div v-if="chatshop.Status == '0'" class="chat-head-wrapper">
                <div v-if="chatshop.unread > 0" class="noti-circular"></div>
                <!-- <a :href="'/shop/' + chatshop.ShopID">
                    <div v-tooltip.top-center="chatshop.ShopName" v-bind:style="{ 'background-image': 'url(' + chatshop.Logo + ')' }" class="chat-shop-logo"></div>
                  </a> -->
                <div
                  v-on:click="closeChat(chatshop.id, index)"
                  class="close-chathead"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#f3155b"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </div>
                <router-link :to="'/shop/' + chatshop.ShopID">
                  <div
                    v-tooltip.top-center="chatshop.ShopName"
                    v-bind:style="{
                      'background-image': 'url(' + chatshop.Logo + ')',
                    }"
                    class="chat-shop-logo"
                  ></div>
                </router-link>
              </div>
            </div>
          </vuescroll>
        </div>
      </div>
      <div class="shop-section-inner">
        <div class="shop-section top-section">
          <div class="section-label">เกี่ยวกับร้านค้า</div>
          {{ shopInfo.Name }} - {{ shopInfo.Location }}<br />
          <div class="shop-side-description">{{ shopInfo.Description }}</div>
        </div>
        <div class="chat-header">ติดต่อร้าน</div>
        <div class="social-media-wrapper contact">
          <div
            v-if="
              shopInfo.Facebook && shopInfo.Facebook.includes('facebook.com')
            "
            class="social-platform"
          >
            <a target="_blank" v-bind:href="shopInfo.Facebook"
              ><img src="../assets/Facebook.png"
            /></a>
            <div class="social-label">Facebook</div>
          </div>
          <div
            v-if="
              shopInfo.Facebook && !shopInfo.Facebook.includes('facebook.com')
            "
            class="social-platform"
          >
            <a
              target="_blank"
              v-bind:href="'https://www.facebook.com/' + shopInfo.Facebook"
              ><img src="../assets/Facebook.png"
            /></a>
            <div class="social-label">Facebook</div>
          </div>
          <div v-if="shopInfo.Instagram" class="social-platform">
            <a
              target="_blank"
              v-bind:href="'https://www.instagram.com/' + shopInfo.Instagram"
              ><img src="../assets/Instagram.png"
            /></a>
            <div class="social-label">Instagram</div>
          </div>
          <div
            v-if="shopInfo.Line.includes('@') && shopInfo.Line"
            class="social-platform"
          >
            <a
              target="_blank"
              v-bind:href="'https://line.me/R/ti/p/' + shopInfo.Line"
              ><img src="../assets/LINE.png"
            /></a>
            <div class="social-label">LINE</div>
          </div>
          <div
            v-if="!shopInfo.Line.includes('@') && shopInfo.Line"
            class="social-platform"
          >
            <a
              target="_blank"
              v-bind:href="'https://line.me/ti/p/~' + shopInfo.Line"
              ><img src="../assets/LINE.png"
            /></a>
            <div class="social-label">LINE</div>
          </div>
          <div v-if="shopInfo.Twitter" class="social-platform">
            <a
              target="_blank"
              v-bind:href="'https://twitter.com/' + shopInfo.Twitter"
              ><img src="../assets/Twitter.png"
            /></a>
            <div class="social-label">Twitter</div>
          </div>
          <div v-if="shopInfo.Phone" class="social-platform">
            <a target="_blank" v-bind:href="'tel:' + shopInfo.Phone"
              ><img src="../assets/telephone.png"
            /></a>
            <div class="social-label">โทร</div>
          </div>
        </div>
        <!--<div class="shop-section top-section">
          <div class="section-label">เกี่ยวกับร้านค้า</div>
          {{shopInfo.Name}} - {{shopInfo.Location}}<br>
          <div class="shop-side-description">{{shopInfo.Description}}</div>
        </div>
        <div v-if="chatproductImage == ''" class="chat-header">
            SHOP CHAT
          </div>
          <div v-else class="chat-header">
            <div v-if="chatproductImage.split('/')[4] == 'images'" class="post-image">
                <img v-bind:src="chatproductImage"/>
            </div>
            <div v-else class="post-video">
              <VideoPlayer :videoURL='chatproductImage'></VideoPlayer>
            </div>
            <div class="chat-product-name">
              {{chatproductName}}
            </div>
          </div>
        <div id="stream-chatbox-mobile" class="chat-body">
          <div v-bind:class="msg.sender" v-mod v-for="msg in chatmsgs" v-bind:key="msg.id" class="chat-msg">
            <div v-if="msg.sender == 'Customer'" class="bubble-speech bubble-right">
              <div class="message">
                {{msg.text}}
              </div>
            </div>
            <div v-else class="bubble-speech bubble-left">
              <div class="message">
                {{msg.text}}
              </div>
            </div>
          </div>
        </div>
        <div class="chat-input">
          <input @focus="checkUserStatus()" v-model="usermsg" @keyup.enter="sendChat()" placeholder="Send a Message"/>
          <div v-if="userInfo.id" class="send-chat">
            <div v-on:click="sendChat()" class="chatbtt">Chat</div>
            <div v-bind:class="chatSettingClass" class="chat-setting">
              <div class="setting-header">ตั้งค่าการแชท</div>
              <font-awesome-icon v-on:click="toggleChatSetting()" class="close-icon" icon="times" />
              <div class="chat-setting-header">
                ชื่อที่แสดงของคุณ
              </div>
              <input v-model="chatname"/>
              <div v-on:click="signOut()" class="signout">ออกจากระบบ</div>
            </div>
            <font-awesome-icon v-on:click="toggleChatSetting()" class="chat-setting-icon" icon="cog" />
          </div>
          <div v-else>
            <div v-on:click="goLogin()" class="signinbtt">เข้าสู่ระบบ</div>
          </div>
        </div>-->
      </div>
      <div class="clearfix"></div>
    </div>
    <sweet-modal ref="register" modal-theme="dark" overlay-theme="dark">
      <CustomerLogin @close="closeModal()" ref="cutomerLogin"></CustomerLogin>
    </sweet-modal>
  </div>
</template>

<script>
import axios from "axios";
import VideoPlayer from "@/components/VideoPlayer";
import CustomerLogin from "@/components/CustomerLogin";
import VueEasyLightbox from "vue-easy-lightbox";
import { SweetModal } from "sweet-modal-vue";
import vuescroll from "vuescroll";
import { mapGetters, mapActions } from "vuex";
import { backendURL } from "../config";

export default {
  name: "ShopPost",
  components: {
    VideoPlayer,
    VueEasyLightbox,
    SweetModal,
    CustomerLogin,
    vuescroll,
  },
  sockets: {
    connect: function () {
      // eslint-disable-next-line
      console.log("socket connected");
    },
    shopChat: function (shopmsg) {
      // this.chatmsgs.push(shopmsg)
      setTimeout(function () {
        var objDiv = document.getElementById("stream-chatbox");
        objDiv.scrollTop = 10000000000;
      }, 100);
      this.getNewChat(shopmsg);
    },
  },
  beforeMount() {
    this.setShopMenuClass("show-menu");
    this.getAllShopPosts(this.$route.params.id);
    this.getShopInfo(this.$route.params.id);
    this.getAllShopCategory(this.$route.params.id);
    var self = this;
    setTimeout(function () {
      if(localStorage.getItem('cart')){
        self.loadCart(JSON.parse(localStorage.getItem('cart')));
        /*let pos = self.cart.map(function(e) { return e.shopid; }).indexOf(self.$route.params.id.toString());
        if(pos !== -1){
          let products = self.cart[pos].product;
          for(let i=0; i < products.length; i++) {
            self.cartamount = self.cartamount + products[i].amount;
            for (let j = 0; j < self.allShopPosts.length; j++) {
              if (self.allShopPosts[j].id == products[i].pid) {
                self.allShopPosts[j].amount = products[i].amount;
              }
            }
          }
        }*/
        self.updateCart();
      }
    }, 500);
    var chatSetting = JSON.parse(localStorage.getItem("userSetting"));
    // eslint-disable-next-line
    // console.log(chatSetting)
    if (localStorage.getItem("userSetting")) {
      this.chatname = chatSetting.name;
      var userInfo = { id: chatSetting.id };
      this.setUserLogin(userInfo);
      for (var i = 0; i < this.settingColors.length; i++) {
        if (this.settingColors[i].code == chatSetting.color) {
          this.settingColors[i].colorClass = "active";
          this.chatcolor = this.settingColors[i].code;
        }
      }
      if (this.userInfo.id) {
        // eslint-disable-next-line
        this.$socket.$subscribe("shop" + this.userInfo.id, (payload) => {
          var notiSound = document.getElementById("notiSound");
          notiSound.play();
          if (self.shopInfo.id == this.$route.params.id) {
            this.getChatHistory();
            this.getChatShops();
          }
        });
        // eslint-disable-next-line
        this.$socket.$subscribe("test", (payload) => {
          var notiSound = document.getElementById("notiSound");
          notiSound.play();
        });
      }
    } else {
      this.chatname = Math.floor(Math.random() * 9000 + 1);
      this.chatcolor = ((Math.random() * 0xffffff) << 0).toString(16);
    }
    // eslint-disable-next-line
    // this.$socket.$subscribe('shop'+this.$route.params.id+this.userInfo.id, payload => {
    setTimeout(function () {
      self.getChatHistory();
      self.getChatShops();
    }, 500);
  },
  watch: {
    // when redirect to new category_name, this will be callback
    $route() {
      // react to route changes...
      this.allchats = [];
      this.chatmsgs = [];
      this.chatproductImage = "";
      this.chatproductName = "";
      this.chatpostID = "";
      this.chatroomid = "";
      this.getAllShopPosts(this.$route.params.id);
      this.getShopInfo(this.$route.params.id);
      this.getAllShopCategory(this.$route.params.id);
      /* if(this.userInfo.id){
          // eslint-disable-next-line
          this.$socket.$subscribe('shop'+this.userInfo.id, payload => {
            var notiSound = document.getElementById("notiSound"); 
            notiSound.play();
            if (self.shopInfo.id == this.$route.params.id) {
              this.getChatHistory()
              this.getChatShops()
            }
          });
        } */
      var self = this;
      setTimeout(function () {
        self.getChatHistory();
        self.getChatShops();
      }, 500);
    },
  },
  data() {
    return {
      shopcart: [],
      cartamount: 0,
      imgs: "", // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0,
      usermsg: "",
      userChatmsg: "",
      chatname: "",
      allchats: [],
      chatmsgs: [],
      chatcolor: "",
      chatSettingClass: "",
      chatroomid: "",
      chatShops: "",
      chatproductImage: "",
      chatproductName: "",
      chatpostID: "",
      currentShopUnread: 0,
      settingColors: [
        { code: "ff0000", style: "background-color:#ff0000", colorClass: "" },
        { code: "0000ff", style: "background-color:#0000ff", colorClass: "" },
        { code: "008000", style: "background-color:#008000", colorClass: "" },
        { code: "b22222", style: "background-color:#b22222", colorClass: "" },
        { code: "ff7f50", style: "background-color:#ff7f50", colorClass: "" },
        { code: "9acd32", style: "background-color:#9acd32", colorClass: "" },
        { code: "ff4500", style: "background-color:#ff4500", colorClass: "" },
        { code: "2e8b57", style: "background-color:#2e8b57", colorClass: "" },
        { code: "daa520", style: "background-color:#daa520", colorClass: "" },
        { code: "d2691e", style: "background-color:#d2691e", colorClass: "" },
        { code: "5f9ea0", style: "background-color:#5f9ea0", colorClass: "" },
        { code: "1e90ff", style: "background-color:#1e90ff", colorClass: "" },
        { code: "ff69b4", style: "background-color:#ff69b4", colorClass: "" },
        { code: "8a2be2", style: "background-color:#8a2be2", colorClass: "" },
      ],
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {
          background: "#196A9F",
          opacity: "0",
        },
      },
    };
  },
  methods: {
    showSingle(url, desc) {
      this.imgs = [{ title: desc, src: url }];
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    goLogin() {
      this.$router.push({
        name: "AuthBack",
        query: { shop: this.$route.params.id },
      });
    },
    // eslint-disable-next-line
    setShopCategory(index, categoryID) {
      /* this.setPostActiveCategory(index);
          var params = {
            id: this.$route.params.id,
            CategoryID: categoryID
          }
          if (categoryID == "0"){
            this.getAllShopPosts(this.$route.params.id);
          } else {
            this.getAllShopPostsByCategory(params)
          } */
    },
    // eslint-disable-next-line
    setShopCategorybyID(categoryID) {
      /* for (var i=0; i<this.allShopCategories.length; i++){
              if(this.allShopCategories[i].CategoryID == categoryID){
                this.setPostActiveCategory(i);
              }
          }
          var params = {
            id: this.$route.params.id,
            CategoryID: categoryID
          }
          if (categoryID == "0"){
            this.getAllShopPosts(this.$route.params.id);
          } else {
            this.getAllShopPostsByCategory(params)
          } */
    },
    async getChatHistory() {
      if (localStorage.getItem("userSetting")) {
        var params = {
          SellerID: this.shopInfo.FCusID,
          CustomerID: this.userInfo.id,
          Reader: "Customer",
        };
        const response = await axios.post(
          `${backendURL}/getnotreadchats/`,
          params
        );
        // eslint-disable-next-line
        console.log("chats", response);
        this.chatroomid = response.data.chatroom[0].id;
        var sellerid = response.data.SellerID;
        var chat = {};
        // var chatmsgs = []
        this.allchats = [];
        this.currentShopUnread = 0;
        var allPosts = this.allShopPosts;
        // eslint-disable-next-line
        // console.log(allPosts)
        for (var x = 0; x < this.allShopPosts.length; x++) {
          this.allShopPosts[x].chats = [];
          this.allShopPosts[x].unread = 0;
          this.allShopPosts[x].notiID = "";
        }
        for (var i = 0; i < response.data.chats.length; i++) {
          chat = {};
          chat.id = response.data.chats[i].id;
          chat.PostID = response.data.chats[i].PostID;
          chat.text = response.data.chats[i].ChatText;
          if (response.data.chats[i].ChatFrom === sellerid) {
            chat.sender = "Seller";
          } else {
            chat.sender = "Customer";
          }
          this.allchats.push(chat);
          /*if (this.chatpostID  !== "") {
                if (response.data.chats[i].PostID == this.chatpostID){
                  this.chatmsgs.push(chat)
                }
              }else{
                if (response.data.chats[i].PostID == "" || response.data.chats[i].PostID == null){
                  this.chatmsgs.push(chat)
                }
              }*/
          for (var c = 0; c < this.allShopPosts.length; c++) {
            if (this.allShopPosts[c].isRotate === true) {
              if (response.data.chats[i].PostID == this.allShopPosts[c].id) {
                this.allShopPosts[c].chats.push(chat);
              }
            }
          }
          if (
            response.data.chats[i].Status === "0" &&
            response.data.chats[i].ChatFrom === sellerid &&
            this.chatpostID !== "" &&
            response.data.chats[i].PostID === null
          ) {
            this.currentShopUnread = parseInt(this.currentShopUnread) + 1;
          }
          var addNotiID = false;
          for (var p = 0; p < allPosts.length; p++) {
            if (allPosts[p].id === response.data.chats[i].PostID) {
              if (
                response.data.chats[i].Status === "0" &&
                response.data.chats[i].ChatFrom === sellerid
              ) {
                if (allPosts[p].isRotate === false) {
                  if (allPosts[p].unread) {
                    allPosts[p].unread = parseInt(allPosts[p].unread) + 1;
                  } else {
                    allPosts[p].unread = 1;
                    if (addNotiID == false) {
                      allPosts[p].notiID = "anchorNoti";
                      addNotiID = true;
                    }
                  }
                }
              }
            }
          }
        }
        this.updateAllPosts(allPosts);
        this.setPostShopAsRead();
        var self = this;
        setTimeout(function () {
          self.$scrollTo("#anchorNoti", 500);
        }, 500);
        setTimeout(function () {
          var objDiv = document.getElementById("stream-chatbox");
          objDiv.scrollTop = 10000000000;
        }, 100);
        setTimeout(function () {
          var objDiv = document.getElementById("stream-chatbox-mobile");
          objDiv.scrollTop = 10000000000;
        }, 100);
        setTimeout(function () {
          var objDiv = document.getElementById("post-chatbox");
          objDiv.scrollTop = 10000000000;
        }, 100);
      }
    },
    setPostShopAsRead() {
      var pid = this.chatpostID;
      var self = this;
      if (this.chatpostID == "" || this.chatpostID == null) {
        pid = "null";
      }
      axios({
        url: `${backendURL}/readchat/`,
        method: "POST",
        data: {
          FCusID: this.userInfo.id,
          RoomID: this.chatroomid,
          PostID: pid,
        },
      })
        .then(() => {
          self.reCountUnread();
        })
        // .catch((error) => {
        //   console.error(error);
        // });
    },
    getChatShops() {
      if (this.userInfo.id) {
        var self = this;
        var unread = 0;
        axios({
          url: `${backendURL}/getchatroombycustomer/${this.userInfo.id}`,
          method: "GET",
        })
          .then(function (response) {
            var shopChats = [];
            for (var i = 0; i < response.data.chatroom.length; i++) {
              // eslint-disable-next-line
              // console.log(response.data.chatroom[i])
              if (response.data.chatroom[i].ShopID !== self.shopInfo.id) {
                shopChats.push(response.data.chatroom[i]);
              }
              unread = unread + parseInt(response.data.chatroom[i].unread);
            }
            self.setUnreadChat(unread);
            self.chatShops = shopChats;
          })
          .catch(function (error) {
            // eslint-disable-next-line
            console.log(error);
          });
      }
    },
    reCountUnread() {
      if (this.userInfo.id) {
        var self = this;
        var unread = 0;
        axios({
          url: `${backendURL}/getchatroombycustomer/${this.userInfo.id}`,
          method: "GET",
        })
          .then(function (response) {
            for (var i = 0; i < response.data.chatroom.length; i++) {
              unread = unread + parseInt(response.data.chatroom[i].unread);
            }
            self.setUnreadChat(unread);
          })
          .catch(function (error) {
            // eslint-disable-next-line
            console.log(error);
          });
      }
    },
    sendChat() {
      this.usermsg = this.userChatmsg;
      this.userChatmsg = "";
      var badwords = [
        "เหี้ย",
        "สัด",
        "หมา",
        "ควาย",
        "แรด",
        "กระทิง",
        "ฟาย",
        "หมาบ้า",
        "มัจจุราช",
        "เควี่ย",
        "เชี่ย",
        "เงี่ยน",
        "ส้นตีน",
        "ชาติชั่ว",
        "สถุล",
        "ระยำ",
        "สันดาน",
        "ไอ้สัตว์นรก",
        "พ่อมึงตาย",
        "แม่มึงตาย",
        "ชิงหมาเกิด",
        "จัญไร",
        "ใจหมา",
        "ช้างเย็ด",
        "อัปปรี",
        "ชาติหมา",
        "อีวาฬ",
        "หน้าส้นตีน",
        "ไอเหี้ย",
        "ไอสัด",
        "ไอ้หมา",
        "ไอ้ควาย",
        "นรกแดกกบาล",
        "เศษนรก",
        "กวนส้นตีน",
        "ล่อกัน",
        "เอากัน",
        "แทงกัน",
        "ยิงกัน",
        "ปี้กัน",
        "ตีกัน",
        "หน้าตัวเมืย",
        "หน้าหี",
        "หน้าควย",
        "ไอ้ขึ้หมา",
        "แมงดา",
        "กระดอ",
        "อีหน้าหี",
        "กระสัน",
        "ไอเหี้ยหน้าหี",
        "อีเหี้ย",
        "อีสัด",
        "อีหมา",
        "อีควาย",
        "อีปลาวาฬ",
        "อีหน้าหมา",
        "สาด",
        "อีฟันหมาบ้า",
        "อีหน้าควาย",
        "กินขี้ปี้เยี่ยว",
        "อีปลาเงือก",
        "ไอ้ส้นตีน",
        "เสือกพะยูน",
        "พ่อมึง",
        "แม่มึง",
        "พ่อง",
        "แม่ง",
        "พ่อมึงตาย",
        "แม่มึงตาย",
        "ควย",
        "หี",
        "เจี๊ยว",
        "แดก",
        "ขี้",
        "เยี่ยว",
        "ขึ้แตก",
        "จรวย",
        "ไอเข้",
        "ไอ้สัส",
        "ขายตัว",
        "ลูกอีกะหรี่",
        "ลูกอีดอกทอง",
        "ลูกอีสาด",
        "ยัดแม่",
        "ฟักยู",
        "อีอับปรี",
        "อีกระหรี่",
        "อีกะหรี",
        "อีชาติชั้ว",
        "อีช้างเย๊ด",
        "อีห่า",
        "อยากเอาหญิง",
        "ระยำ",
        "ทมิฬ",
        "ลูกโสเภณี",
        "หน้าโง่",
        "อีดอก",
        "กรี่",
        "อีบ้า",
        "ahole",
        "anus",
        "ash0le",
        "ash0les",
        "asholes",
        "ass",
        "Ass Monkey",
        "Assface",
        "assh0le",
        "assh0lez",
        "asshole",
        "assholes",
        "assholz",
        "asswipe",
        "azzhole",
        "bassterds",
        "bastard",
        "bastards",
        "bastardz",
        "basterds",
        "basterdz",
        "Biatch",
        "bitch",
        "bitches",
        "Blow Job",
        "boffing",
        "butthole",
        "buttwipe",
        "c0ck",
        "c0cks",
        "c0k",
        "Carpet Muncher",
        "cawk",
        "cawks",
        "Clit",
        "cnts",
        "cntz",
        "cock",
        "cockhead",
        "cock-head",
        "cocks",
        "CockSucker",
        "cock-sucker",
        "crap",
        "cum",
        "cunt",
        "cunts",
        "cuntz",
        "dick",
        "dild0",
        "dild0s",
        "dildo",
        "dildos",
        "dilld0",
        "dilld0s",
        "dominatricks",
        "dominatrics",
        "dominatrix",
        "dyke",
        "enema",
        "f u c k",
        "f u c k e r",
        "fag",
        "fag1t",
        "faget",
        "fagg1t",
        "faggit",
        "faggot",
        "fagit",
        "fags",
        "fagz",
        "faig",
        "faigs",
        "fart",
        "flipping the bird",
        "fuck",
        "fucker",
        "fuckin",
        "fucking",
        "fucks",
        "Fudge Packer",
        "fuk",
        "Fukah",
        "Fuken",
        "fuker",
        "Fukin",
        "Fukk",
        "Fukkah",
        "Fukken",
        "Fukker",
        "Fukkin",
        "g00k",
        "gay",
        "gayboy",
        "gaygirl",
        "gays",
        "gayz",
        "God-damned",
        "h00r",
        "h0ar",
        "h0re",
        "hells",
        "hoar",
        "hoor",
        "hoore",
        "jackoff",
        "jap",
        "japs",
        "jerk-off",
        "jisim",
        "jiss",
        "jizm",
        "jizz",
        "knob",
        "knobs",
        "knobz",
        "kunt",
        "kunts",
        "kuntz",
        "Lesbian",
        "Lezzian",
        "Lipshits",
        "Lipshitz",
        "masochist",
        "masokist",
        "massterbait",
        "masstrbait",
        "masstrbate",
        "masterbaiter",
        "masterbate",
        "masterbates",
        "Motha Fucker",
        "Motha Fuker",
        "Motha Fukkah",
        "Motha Fukker",
        "Mother Fucker",
        "Mother Fukah",
        "Mother Fuker",
        "Mother Fukkah",
        "Mother Fukker",
        "mother-fucker",
        "Mutha Fucker",
        "Mutha Fukah",
        "Mutha Fuker",
        "Mutha Fukkah",
        "Mutha Fukker",
        "n1gr",
        "nastt",
        "nigger;",
        "nigur;",
        "niiger;",
        "niigr;",
        "orafis",
        "orgasim;",
        "orgasm",
        "orgasum",
        "oriface",
        "orifice",
        "orifiss",
        "packi",
        "packie",
        "packy",
        "paki",
        "pakie",
        "paky",
        "pecker",
        "peeenus",
        "peeenusss",
        "peenus",
        "peinus",
        "pen1s",
        "penas",
        "penis",
        "penis-breath",
        "penus",
        "penuus",
        "Phuc",
        "Phuck",
        "Phuk",
        "Phuker",
        "Phukker",
        "polac",
        "polack",
        "polak",
        "Poonani",
        "pr1c",
        "pr1ck",
        "pr1k",
        "pusse",
        "pussee",
        "pussy",
        "puuke",
        "puuker",
        "queer",
        "queers",
        "queerz",
        "qweers",
        "qweerz",
        "qweir",
        "recktum",
        "rectum",
        "retard",
        "sadist",
        "scank",
        "schlong",
        "screwing",
        "semen",
        "sex",
        "sexy",
        "Sh!t",
        "sh1t",
        "sh1ter",
        "sh1ts",
        "sh1tter",
        "sh1tz",
        "shit",
        "shits",
        "shitter",
        "Shitty",
        "Shity",
        "shitz",
        "Shyt",
        "Shyte",
        "Shytty",
        "Shyty",
        "skanck",
        "skank",
        "skankee",
        "skankey",
        "skanks",
        "Skanky",
        "slut",
        "sluts",
        "Slutty",
        "slutz",
        "son-of-a-bitch",
        "tit",
        "turd",
        "va1jina",
        "vag1na",
        "vagiina",
        "vagina",
        "vaj1na",
        "vajina",
        "vullva",
        "vulva",
        "w0p",
        "wh00r",
        "wh0re",
        "whore",
        "xrated",
        "xxx",
        "b!+ch",
        "bitch",
        "blowjob",
        "clit",
        "arschloch",
        "fuck",
        "shit",
        "ass",
        "asshole",
        "b!tch",
        "b17ch",
        "b1tch",
        "bastard",
        "bi+ch",
        "boiolas",
        "buceta",
        "c0ck",
        "cawk",
        "chink",
        "cipa",
        "clits",
        "cock",
        "cum",
        "cunt",
        "dildo",
        "dirsa",
        "ejakulate",
        "fatass",
        "fcuk",
        "fuk",
        "fux0r",
        "hoer",
        "hore",
        "jism",
        "kawk",
        "l3itch",
        "l3i+ch",
        "lesbian",
        "masturbate",
        "masterbat*",
        "masterbat3",
        "motherfucker",
        "s.o.b.",
        "mofo",
        "nazi",
        "nigga",
        "nigger",
        "nutsack",
        "phuck",
        "pimpis",
        "pusse",
        "pussy",
        "scrotum",
        "sh!t",
        "shemale",
        "shi+",
        "sh!+",
        "slut",
        "smut",
        "teets",
        "tits",
        "boobs",
        "b00bs",
        "teez",
        "testical",
        "testicle",
        "titt",
        "w00se",
        "jackoff",
        "wank",
        "whoar",
        "whore",
        "*damn",
        "*dyke",
        "*fuck*",
        "*shit*",
        "@$$",
        "amcik",
        "andskota",
        "arse*",
        "assrammer",
        "ayir",
        "bi7ch",
        "bitch*",
        "bollock*",
        "breasts",
        "butt-pirate",
        "cabron",
        "cazzo",
        "chraa",
        "chuj",
        "Cock*",
        "cunt*",
        "d4mn",
        "daygo",
        "dego",
        "dick*",
        "dike*",
        "dupa",
        "dziwka",
        "ejackulate",
        "Ekrem*",
        "Ekto",
        "enculer",
        "faen",
        "fag*",
        "fanculo",
        "fanny",
        "feces",
        "feg",
        "Felcher",
        "ficken",
        "fitt*",
        "Flikker",
        "foreskin",
        "Fotze",
        "Fu(*",
        "fuk*",
        "futkretzn",
        "gay",
        "gook",
        "guiena",
        "h0r",
        "h4x0r",
        "hell",
        "helvete",
        "hoer*",
        "honkey",
        "Huevon",
        "hui",
        "injun",
        "jizz",
        "kanker*",
        "kike",
        "klootzak",
        "kraut",
        "knulle",
        "kuk",
        "kuksuger",
        "Kurac",
        "kurwa",
        "kusi*",
        "kyrpa*",
        "lesbo",
        "mamhoon",
        "masturbat*",
        "merd*",
        "mibun",
        "monkleigh",
        "mouliewop",
        "muie",
        "mulkku",
        "muschi",
        "nazis",
        "nepesaurio",
        "nigger*",
        "orospu",
        "paska*",
        "perse",
        "picka",
        "pierdol*",
        "pillu*",
        "pimmel",
        "piss*",
        "pizda",
        "poontsee",
        "poop",
        "porn",
        "p0rn",
        "pr0n",
        "preteen",
        "pula",
        "pule",
        "puta",
        "puto",
        "qahbeh",
        "queef*",
        "rautenberg",
        "schaffer",
        "scheiss*",
        "schlampe",
        "schmuck",
        "screw",
        "sh!t*",
        "sharmuta",
        "sharmute",
        "shipal",
        "shiz",
        "skribz",
        "skurwysyn",
        "sphencter",
        "spic",
        "spierdalaj",
        "splooge",
        "suka",
        "b00b*",
        "testicle*",
        "titt*",
        "twat",
        "vittu",
        "wank*",
        "wetback*",
        "wichser",
        "wop*",
        "yed",
        "zabourah",
      ];
      if (this.userInfo.id) {
        if (this.usermsg.trim() !== "") {
          var censored = this.usermsg;
          for (var c = 0; c < badwords.length; c++) {
            censored = censored.replace(badwords[c], "****");
          }
          var self = this;
          // eslint-disable-next-line
          // console.log(this.chatproductName+','+this.chatproductImage+','+this.chatpostID)
          var chatdata;
          if (this.chatpostID != "") {
            chatdata = {
              ChatRoomID: this.chatroomid,
              ChatFrom: this.userInfo.id,
              ChatText: censored,
              ProductName: this.chatproductName,
              ProductImage: this.chatproductImage,
              PostID: this.chatpostID,
              Status: "0",
            };
          } else {
            chatdata = {
              ChatRoomID: this.chatroomid,
              ChatFrom: this.userInfo.id,
              ChatText: censored,
              Status: "0",
            };
          }
          axios({
            url: `${backendURL}/addchat/`,
            method: "POST",
            data: chatdata,
          })
            .then(function () {
              var d = new Date();
              var msg = {
                id: d.getTime(),
                user: self.chatname,
                color: "color:#f3155b",
                text: self.usermsg,
                room: "shop" + self.shopInfo.id,
                usernoti: "shop" + self.shopInfo.id,
                userid: "",
                shopid: localStorage.getItem("shopID"),
              };
              // self.$socket.client.emit('customerChat', msg)
              self.$socket.client.emit("shopChat", msg);
              self.getChatHistory();
              self.sendNotification();
            })
            .catch(function (error) {
              // eslint-disable-next-line
              console.log(error);
            });
        }
      } else {
        this.$refs.cutomerLogin.reset();
        this.$refs.register.open();
      }
    },
    async getNewChat() {
      var params = {
        SellerID: this.shopInfo.FCusID,
        CustomerID: this.userInfo.id,
        Reader: "Customer",
      };
      const response = await axios.post(`${backendURL}/getchats/`, params);
      // eslint-disable-next-line
      // console.log('chats',response)
      this.chatroomid = response.data.chatroom[0].id;
      var sellerid = response.data.SellerID;
      var chat = {};
      //this.chatmsgs = []
      for (var i = 0; i < response.data.chats.length; i++) {
        chat = {};
        chat.id = response.data.chats[i].id;
        chat.PostID = response.data.chats[i].PostID;
        chat.text = response.data.chats[i].ChatText;
        if (response.data.chats[i].ChatFrom === sellerid) {
          chat.sender = "Seller";
        } else {
          chat.sender = "Customer";
        }
        /*if (this.chatpostID  !== "") {
              if (response.data.chats[i].PostID == this.chatpostID){
                this.chatmsgs.push(chat)
              }
            }else{
              if (response.data.chats[i].PostID == "" || response.data.chats[i].PostID == null){
                this.chatmsgs.push(chat)
              }
            }*/
        for (var c = 0; c < this.allShopPosts.length; c++) {
          if (this.allShopPosts[c].isRotate === true) {
            if (response.data.chats[i].PostID == this.allShopPosts[c].id) {
              this.allShopPosts[c].chats.push(chat);
            }
          }
        }
      }
      setTimeout(function () {
        var objDiv = document.getElementById("stream-chatbox");
        objDiv.scrollTop = 10000000000;
      }, 100);
      setTimeout(function () {
        var objDiv = document.getElementById("stream-chatbox-mobile");
        objDiv.scrollTop = 10000000000;
      }, 100);
    },
    checkUserStatus() {
      if (!this.userInfo.id) {
        // this.$refs.cutomerLogin.reset()
        // this.$refs.register.open();
        this.$router.push({
          name: "AuthBack",
          query: { shop: this.$route.params.id },
        });
      } else {
        setTimeout(function () {
          var objDiv = document.getElementById("stream-chatbox-mobile");
          objDiv.scrollTop = 10000000000;
        }, 500);
      }
    },
    sendNotification: function () {
      var appid = "f2889da0-ef8f-486e-b152-9b66de49e91e";
      //var userpushid = [this.shopInfo.NotificationID]
      var userpushid = [this.$route.params.id];
      var self = this;
      axios({
        url: "https://onesignal.com/api/v1/notifications",
        method: "POST",
        headers: {
          Authorization:
            "Basic MTc4NDNlNGEtZTE0Yy00MWYxLWFjMDMtOGEyODNiZmNiZGQ3",
        },
        data: {
          app_id: appid,
          include_external_user_ids: userpushid,
          headings: { en: this.chatname },
          contents: { en: this.usermsg },
        },
      })
        .then(function () {
          self.usermsg = "";
        })
        .catch(function (error) {
          // eslint-disable-next-line
          console.log(error.response);
        });
    },
    closeModal() {
      this.$refs.register.close();
      var chatSetting = JSON.parse(localStorage.getItem("userSetting"));
      if (localStorage.getItem("userSetting")) {
        this.chatname = chatSetting.name;
        for (var i = 0; i < this.settingColors.length; i++) {
          if (this.settingColors[i].code == chatSetting.color) {
            this.settingColors[i].colorClass = "active";
            this.chatcolor = this.settingColors[i].code;
          }
        }
      }
    },
    toggleChatSetting() {
      var i;
      if (this.chatSettingClass == "") {
        this.chatSettingClass = "active";
      } else {
        this.chatSettingClass = "";
        var selectedcolor = "";
        for (i = 0; i < this.settingColors.length; i++) {
          if (this.settingColors[i].colorClass == "active") {
            selectedcolor = this.settingColors[i].code;
          }
        }
        var chatSetting = JSON.parse(localStorage.getItem("userSetting"));
        var usersetting = {
          id: chatSetting.id,
          color: selectedcolor,
          name: this.chatname,
        };
        localStorage.setItem("userSetting", JSON.stringify(usersetting));
      }
    },
    signOut() {
      this.chatmsgs = [];
      this.logOutUser();
    },
    changeColor(color) {
      for (var i = 0; i < this.settingColors.length; i++) {
        this.settingColors[i].colorClass = "";
        if (this.settingColors[i].code == color.code) {
          this.settingColors[i].colorClass = "active";
          this.chatcolor = this.settingColors[i].code;
        }
      }
    },
    setChatProduct(postID, productImage, productName, post) {
      if (this.userInfo.id) {
        this.chatproductImage = productImage;
        this.chatproductName = productName;
        this.chatpostID = postID;
        for (var c = 0; c < this.allShopPosts.length; c++) {
          this.allShopPosts[c].isRotate = false;
        }
        var chatmsgs = [];
        /* if (screen.width < 1100){
              this.setSideBarMenuClass("show-shopside-menu")
              this.setMenuClass("menu-close")
            } */
        // eslint-disable-next-line
        // console.log(this.allchats)
        for (var i = 0; i < this.allchats.length; i++) {
          if (this.allchats[i].PostID == postID) {
            // eslint-disable-next-line
            // console.log(this.allchats[i])
            chatmsgs.push(this.allchats[i]);
          }
        }
        post.chats = chatmsgs;
        this.setPostShopAsRead();
        var allPosts = this.allShopPosts;
        for (var p = 0; p < allPosts.length; p++) {
          if (allPosts[p].id === postID) {
            allPosts[p].unread = 0;
          }
        }
        this.updateAllPosts(allPosts);
        if (postID === null) {
          this.currentShopUnread = 0;
        }
        setTimeout(function () {
          var objDiv = document.getElementById("stream-chatbox");
          objDiv.scrollTop = 10000000000;
        }, 100);
        setTimeout(function () {
          var objDiv = document.getElementById("stream-chatbox-mobile");
          objDiv.scrollTop = 10000000000;
        }, 100);
        setTimeout(function () {
          var objDiv = document.getElementById("post-chatbox");
          objDiv.scrollTop = 10000000000;
        }, 100);
      } else {
        this.$router.push({
          name: "AuthBack",
          query: { shop: this.$route.params.id },
        });
      }
    },
    closeAllMenu: function () {
      this.setMenuClass("menu-close");
      this.setSideBarMenuClass("hide-shopside-menu");
    },
    closeChat: function (shopid, index) {
      var self = this;
      if (window.confirm("แน่ใจหรือว่าต้องการปิดร้านนี้")) {
        axios({
          url: `${backendURL}/closebubble/`,
          method: "POST",
          data: { id: shopid },
        })
          .then(function () {
            self.chatShops.splice(index, 1);
          })
          .catch(function () {});
      }
    },
    closeProductChat: function (post) {
      post.isRotate = false;
      this.chatpostID = "";
    },
    doAddtoCart: function (pid) {
      var item = {
        shopid: this.$route.params.id.toString(),
        pid: pid
      }
      this.addtoCart(item)
      this.updateCart();
    },
    quantityChange : function (id,amount) {
      var item = {
        shopid: this.$route.params.id.toString(),
        pid: id,
        amount: amount
      }
      this.updateQuantity(item);
      this.updateCart();
    },
    updateCart: function () {
      this.cartamount = 0;
      let pos = this.cart.map(function(e) { return e.shopid; }).indexOf(this.$route.params.id.toString());
      if(pos !== -1){
        let products = this.cart[pos].product;
        for(let i=0; i < products.length; i++) {
          this.cartamount = this.cartamount + parseInt(products[i].amount);
          for (let j = 0; j < this.allShopPosts.length; j++) {
            if (this.allShopPosts[j].id == products[i].pid) {
              this.allShopPosts[j].amount = products[i].amount;
            }
          }
        }
      }
    },
    ...mapActions([
      "setMenuClass",
      "setSideBarMenuClass",
      "fetchShops",
      "getAllShopPosts",
      "getAllShopCategory",
      "setShopMenuClass",
      "getShopInfo",
      "setPostActiveCategory",
      "getAllShopPostsByCategory",
      "setUserLogin",
      "logOutUser",
      "setMenuClass",
      "setSideBarMenuClass",
      "updateAllPosts",
      "setUnreadChat",
      "addtoCart",
      "updateQuantity",
      "loadCart"
    ]),
  },
  computed: mapGetters([
    "allShopPosts",
    "shopSideBarState",
    "allShopCategories",
    "shopInfo",
    "userInfo",
    "cart"
  ]),
};
</script>

<style scoped>
/deep/.vel-btns-wrapper .btn__close {
  top: 90px !important;
}
</style>
<style>
.shop-wrapper {
  display: table;
  table-layout: fixed;
  width: 100%;
  vertical-align: top;
  min-height: 100vh;
}
.shop-detail {
  padding-left: 20px;
}
.main-content {
  margin-right: 300px;
  position: relative;
  vertical-align: top;
}
.sidebar-right {
  width: 300px;
  display: table-cell;
  height: 100%;
  background-color: #fff;
  background-image: none;
  -webkit-box-shadow: -7px 5px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: -7px 5px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: -7px 5px 5px 0px rgba(0, 0, 0, 0.15);
  /* border-radius: 0px 0px 0px 50px; */
  position: relative;
  position: fixed;
  top: 0px;
  right: 0px;
}
.sidebar-mobile-right {
  width: 100%;
  height: 100%;
  display: none;
  background-color: #fff;
  background-image: none;
  -webkit-box-shadow: -7px 5px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: -7px 5px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: -7px 5px 5px 0px rgba(0, 0, 0, 0.15);
  padding: 30px 30px 50px;
  position: fixed;
  top: 52px;
  right: 0px;
  z-index: 999;
  transition: all 0.3s ease;
}
.shop-section {
  margin-bottom: 30px;
  font-family: "Kanit";
}
.sidebar-mobile-right .chat-heads {
  width: 25%;
  padding: 4%;
}
.sidebar-mobile-right .shop-section-inner {
  margin-left: 25%;
}
.sidebar-mobile-right .chat-input {
  width: 100%;
  position: relative;
  bottom: auto;
  padding-left: 10px;
  padding-right: 10px;
}
.sidebar-mobile-right .chat-body {
  position: relative;
  width: 97%;
}
.sidebar-mobile-right .current-shop-logo {
  margin-left: auto;
  margin-right: auto;
}
.section-label {
  color: #314052;
  font-size: 17px;
  position: relative;
  margin-bottom: 40px;
  font-weight: bold;
}
.section-label:after {
  content: "";
  position: absolute;
  display: block;
  height: 7px;
  width: 16px;
  border-radius: 2px;
  background-color: #537eff;
  bottom: -18px;
}
.shop-intro img {
  width: 100%;
}
.filter-group {
  margin-top: 10px;
}
.shop-live-mobile {
  border: 1px solid transparent;
  background-color: #fff;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(5, 50, 87, 0.62);
  box-shadow: 0px 3px 5px 0px rgba(5, 50, 87, 0.62);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 15px;
  border-radius: 12px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 75px;
}
.shop-location {
  display: block;
  padding: 2px 5px;
  background-color: #2863ec;
  font-size: 11px;
  line-height: 14px;
  color: #fff;
  border: 1px solid #2863ec;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: auto;
  float: right;
}
.shop-live-mobile {
  display: none;
}
.menu-overlay.hide-shopside-menu {
  display: none;
}
.post-view {
  cursor: pointer;
}
.chat-heads {
  width: 25%;
  float: left;
  height: 100vh;
  -webkit-box-shadow: inset -5px 0px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset -5px 0px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: inset -5px 0px 5px 0px rgba(0, 0, 0, 0.15);
  padding: 20px 10px;
  text-align: center;
}
.current-shop-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin-left: 25px;
  margin-right: auto;
  background-color: #e5e5e5;
  cursor: pointer;
}
.chat-shop-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin-left: 25px;
  margin-right: auto;
  background-color: #e5e5e5;
  margin-bottom: 15px;
  border: 2px solid #e5e5e5;
  position: relative;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
}
.chat-shop-logo:hover {
  border: 2px solid rgba(243, 21, 91, 1);
}
.chat-other-shops {
  border-top: 1px solid #e5e5e5;
  width: 100%;
  margin-top: 20px;
  padding-top: 20px;
  height: 87vh;
  overflow: auto;
}
.chat-box-wrapper {
  width: 100%;
  float: left;
}
.shop-section.top-section {
  border-bottom: 1px solid #e5e5e5;
  padding: 20px;
  margin-bottom: 0px;
}
.chat-header {
  border-bottom: 1px solid #e5e5e5;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
  font-family: "Kanit";
  background-color: #f1f6f8;
}
.chat-header .post-image {
  display: inline-block;
}
.chat-header .post-image img {
  height: 60px;
  width: auto;
}
.chat-header .post-video {
  width: 35%;
  margin-left: auto;
  margin-right: auto;
}
.chat-product-name {
  display: inline-block;
  margin-left: 10px;
  font-family: "Kanit";
}
.chat-body {
  padding: 20px;
  overflow: auto;
  position: absolute;
  height: 66%;
  width: 75%;
}
.chat-input {
  padding-left: 1rem;
  padding-right: 1rem;
  position: absolute;
  bottom: 0px;
  width: 75%;
}
.chat-input input {
  width: 100%;
  padding: 10px;
  background-color: #f2f2f2;
  border: 0px;
}
.send-chat {
  /*padding-right: 1rem;*/
  padding-bottom: 70px;
  background-color: #ffff;
}
.chatbtt,
.signout {
  background-color: #2863ec;
  padding: 5px;
  color: white;
  width: 60px;
  text-align: center;
  margin-left: auto;
  margin-top: 10px;
  border-radius: 3px;
  float: right;
  cursor: pointer;
}
.signout {
  background-color: rgba(243, 21, 91, 1);
  padding: 5px;
  color: white;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 10px;
}
.chatbtt:hover {
  background-color: #588aff;
}
.signout:hover {
  background-color: rgb(216, 12, 77);
}
.chat-user-msg {
  padding-top: 3px;
  padding-bottom: 3px;
}
.chat-user-msg span {
  font-weight: bold;
}
.chat-setting-icon {
  float: right;
  margin-right: 20px;
  margin-top: 19px;
  font-size: 18px;
  cursor: pointer;
}
.chat-setting {
  -webkit-box-shadow: 1px 0px 10px 0px rgba(0, 0, 0, 0.44);
  -moz-box-shadow: 1px 0px 10px 0px rgba(0, 0, 0, 0.44);
  box-shadow: 1px 0px 10px 0px rgba(0, 0, 0, 0.44);
  position: absolute;
  width: 93%;
  left: 3.5%;
  margin-top: -210px;
  background-color: white;
  padding: 10px;
  display: none;
  font-family: "Kanit";
  cursor: pointer;
  z-index: 9999;
}
.setting-header {
  text-align: center;
  font-weight: 600;
}
.chat-setting .close-icon {
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
}
.chat-setting.active {
  display: block;
}
.chat-setting-header {
  margin-top: 20px;
}
.chat-setting-header.section {
  border-top: 1px solid #e3e3e3;
  padding-top: 20px;
  margin-bottom: 15px;
}
.chat-setting input {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  background-color: #f2f2f2;
  border: 0px;
}
.color-choice {
  width: 30px;
  float: left;
  height: 30px;
  margin-right: 13px;
  margin-bottom: 10px;
  border: 2px solid #c3c3c3;
}
.color-choice:hover {
  border: 2px solid #333;
}
.color-choice.active {
  border: 2px solid #333;
}
.signinbtt {
  font-family: "Kanit";
  background-color: #2863ec;
  padding: 9px 5px;
  color: white;
  text-align: center;
  margin-top: 10px;
  border-radius: 3px;
  cursor: pointer;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
.signinbtt:hover {
  background-color: #588aff;
}
.chat-msg {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  display: flex;
}
.chat-msg.Seller {
  justify-content: flex-start;
}
.chat-msg.Customer {
  justify-content: flex-end;
}
.chat-user-msg {
  background-color: white;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 14px;
}
.bubble-speech {
  position: relative;
  font-family: "Source Sans Pro", sans-serif;
  line-height: 22px;
  letter-spacing: 1px;
  margin-bottom: 16px;
  background-color: #efefef;
  padding: 9px 16px;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(5, 50, 87, 0.12);
  box-shadow: 0px 3px 5px 0px rgba(5, 50, 87, 0.12);
}
.bubble-speech.bubble-left {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  max-width: 90%;
}
.bubble-speech.bubble-right {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #99d8ec;
  max-width: 90%;
}
.bubble-speech.bubble-left:after {
  z-index: 9002;
  display: block;
  content: "";
  position: absolute;
  bottom: -11px;
  left: 0;
  border-top: 0 #efefef solid;
  border-bottom: 12px transparent solid;
  border-left: 20px #efefef solid;
}
.bubble-speech.bubble-right:after {
  z-index: 9002;
  display: block;
  content: "";
  position: absolute;
  bottom: -11px;
  right: 0;
  border-top: 0 #99d8ec solid;
  border-bottom: 12px transparent solid;
  border-right: 20px #99d8ec solid;
}
.shop-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  float: left;
}
.tooltip {
  display: block !important;
  z-index: 10000;
}
.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}
.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}
.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}
.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}
.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip[x-placement^="right"] {
  margin-left: 5px;
}
.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.tooltip[x-placement^="left"] {
  margin-right: 5px;
}
.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}
.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}
.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}
.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
.noti-circular {
  background-color: red;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  float: right;
  position: absolute;
  right: 5%;
  z-index: 99;
}
.current-shop-noti-circular {
  background-color: red;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  float: right;
  margin-top: -40px;
}
.unread-noti {
  background-color: red;
  height: 18px;
  width: 18px;
  font-size: 12px;
  border-radius: 50%;
  color: white;
  text-align: center;
  display: inline-block;
  position: relative;
  top: -10px;
}
.chat-head-wrapper:hover .close-chathead {
  background-color: #f3155b;
}
.chat-head-wrapper:hover .close-chathead svg {
  stroke: #ffff;
}
.close-chathead {
  position: absolute;
  /* background-color: #f3155b;*/
  border: 1px solid #f3155b;
  margin-top: 6px;
  height: 28px;
  width: 50px;
  text-align: left;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-left: 5px;
  cursor: pointer;
}
.card {
  /*perspective: 1000;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
     -moz-transform-style: preserve-3d;
     -o-transform-style: preserve-3d;
     -ms-transform-style: preserve-3d;*/
  border-radius: 12px !important;
}
.card.card.isrotate .front {
  /*height: 0px;
    overflow: hidden;*/
  display: none;
}
.card.isrotate {
  transform: rotateY(180deg) !important;
}
.card:hover {
  transform: none;
}
.front {
  position: relative;
  z-index: 9;
  border-radius: 12px;
}
.back {
  position: relative;
  transform: rotateY(180deg);
  left: 0px;
  top: 0px;
  background-color: white;
  width: 100%;
  height: 0px;
  border-radius: 12px;
  display: none;
  z-index: 99999;
}
.card.isrotate .back {
  z-index: 99999;
  display: block;
  height: 650px;
}
.product-chat {
  text-align: center;
  padding-top: 3%;
  padding-bottom: 3%;
  border-bottom: 1px solid #e5e5e5;
}
.product-chat .circular {
  width: 60px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  margin-bottom: 10px;
  background-repeat: no-repeat;
  background-size: cover;
}
.product-chat .post-video-circular {
  width: 60px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  margin-bottom: 10px;
  overflow: hidden;
}
.product-chat .flipback {
  position: absolute;
  left: 15px;
  top: 15px;
}
.product-chat-log {
  position: relative;
  height: 73%;
  overflow: auto;
  width: 100%;
}
.chat-box {
  position: relative;
  height: 8%;
  padding-top: 4%;
  width: 100%;
}
.chat-box {
  border-top: 1px solid #e5e5e5;
}
.chat-box input {
  width: 80%;
  border: 0px;
  height: 100%;
  position: relative;
  border-bottom-left-radius: 12px;
  padding-left: 15px;
  display: inline-block;
}
.send-product-chat {
  background-color: rgba(243, 21, 91, 1);
  display: inline-block;
  width: 17%;
  border-radius: 5px;
  text-align: center;
  padding: 2%;
  margin-left: 2%;
}
.shop-side-description {
  white-space: pre-wrap;
}
.social-media-wrapper.contact {
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.social-media-wrapper.contact .social-platform {
  width: 33%;
  margin-right: 0px;
  margin-left: 0px;
  text-align: center;
  margin-bottom: 20px;
}
.social-media-wrapper.contact .social-platform img {
  width: 40%;
}
.shop-section.top-section.card-mobile {
  background-color: white;
  border-radius: 5px;
  margin-top: 30px;
  display: none;
}
.shop-section.top-section.card-mobile .social-media-wrapper.contact {
  padding: 0px;
  margin-top: 20px;
  margin-bottom: 0px;
}
.shop-section.top-section.card-mobile .social-platform {
  width: 20%;
  margin-left: 0px;
  margin-bottom: 10px;
  text-align: left;
}
.shop-section.top-section.card-mobile .social-platform img {
  width: 60%;
}
.social-label {
  font-family: "Kanit";
}
.post-sku {
  font-size: 12px;
  color: #f3155b;
  margin-bottom: 5px;
}
.from-them {
  position: relative;
  padding: 5px 15px;
  background: #f3155b;
  border-radius: 25px;
  font-size: 13px;
  color: white;
  float: left;
}
.from-them:before {
  content: "";
  position: absolute;
  z-index: 2;
  bottom: -2px;
  left: -7px;
  height: 20px;
  border-left: 20px solid #f3155b;
  border-bottom-right-radius: 16px 14px;
  -webkit-transform: translate(0, -2px);
}
.from-them:after {
  content: "";
  position: absolute;
  z-index: 3;
  bottom: -2px;
  left: 4px;
  width: 26px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
  -webkit-transform: translate(-30px, -2px);
}
.map{
  width:90%;
  margin-left: auto;
  margin-right: auto;
}
.shop-post-container {
  margin-left:-15px;
  width:99%;
}
.shop-filter-item{
  margin-top: 15px;
  text-align: center;
  margin-right: 20px;
  display: inline-block;
  font-weight: 700;
  color: #3893c1;
  border: 1px solid #75b9dd;
  background-color: #d4edfc;
  -webkit-box-shadow: 0 3px 5px 0 rgb(5 50 87 / 62%);
  box-shadow: 0 3px 5px 0 rgb(5 50 87 / 62%);
  -webkit-transition: all .3s ease;
  box-shadow: none;
  border-radius: 4px;
  position: relative;
  min-width: 120px;
  font-weight: 500 !important;
  font-size: 15px !important;
  font-family: "Kanit";
  margin-bottom:25px;
  padding-top:5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
}
.shop-filter-item.active{
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  background-color: white;
}
.shop-filter-item:hover{
  background-color:white;
}
.cart-amount{
  border-radius: 50%;
  background-color: #f3155b;
  color:white;
  width:15px;
  height: 15px;
  font-size: 10px;
  text-align: center;
  display:inline-block;
  position: relative;
  top:-1px;
}
.addtocart-wrapper{
  padding-top:4px;
  padding-bottom: 3px;
}
.cart-quantity-wrapper{
  display: inline-block;
  font-size: 14px;
}
.cart-quantity{
  border-color: #D5D9D9;
  border-radius: 8px;
  color: #0F1111;
  background: #F0F2F2;
  box-shadow: 0 2px 5px 0 rgb(213 217 217 / 50%);
  width:50px;
  text-align: center;
}
.shop-detail .post-shop-location{
  margin-top:15px;
}
.shop-detail .view-wrapper{
  padding-top:10px;
  display: block;
}
.mobile-shop-info{
  display: none;
}
.cart-mobile-wrapper{
  display:none;
}
/*#anchorNoti{
    border:2px solid #f3155b;
  }*/
@media only screen and (min-height: 601px) and (max-height: 850px) {
  .chat-body {
    height: 56%;
  }
}
@media only screen and (min-height: 300px) and (max-height: 600px) {
  .chat-body {
    height: 46%;
  }
}
@media only screen and (max-width: 1100px) {
  .cart-mobile-wrapper{
    display: block;
    position: fixed;
    right:5px;
    top:20px;
    z-index: 9999;
  }
  .cart-mobile-wrapper .cart-amount.mobile{
    right:10px;
  }
  .locked {
    display: none !important;
  }
  .mobile-shop-info{
    display: block;
  }
  .shop-section.top-section.card-mobile {
    display: block;
  }
  /* .shop-section.top-section{
      display:none;
    } */
  .sidebar-right {
    display: none;
  }
  .main-content {
    margin-right: 0px;
  }
  .filter-group {
    margin-left: 0px;
    margin-right: 15px;
    margin-bottom: 20px;
    margin-top: 15px;
  }
  .sidebar-mobile-right {
    display: block;
  }
  .sidebar-mobile-right.hide-shopside-menu {
    right: -100%;
  }
  .sidebar-mobile-right.show-shopside-menu {
    right: 0px;
    padding: 0px;
  }
  .chat-input {
    background-color: #ffffff;
    z-index: 99999;
  }
  .chat-body {
    height: 52vh;
    padding-right: 0px;
    padding-bottom: 0px;
    width: 73%;
  }
  .menu-overlay.show-shopside-menu {
    display: block;
  }
  .shop-live-mobile {
    display: none;
  }
  .sidebar-mobile-right {
    overflow: hidden;
  }
  .chat-input {
    bottom: 35px;
  }
  .shop-detail {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 40px;
  }
  .chat-other-shops {
    height: 80vh;
  }
  .mobile-shop-info{
    border-bottom: 0px !important;
  }
  .mobile-shop-info .shop-logo{
    width:120px;
    height:120px;
    float:none;
    margin-left: auto;
    margin-right: auto;
    margin-top:8px;
  }
  .mobile-shop-info .shop-side-description{
    text-align: center;
    width:100%;
    float:none;
    margin-top: 8px;
  }
  .mobile-shop-info .shop-location-info{
    text-align: center;
    width:100%;
    margin-top: 8px;
    font-weight: bold;
  }
  .mobile-shop-info .social-platform{
    margin-bottom:0px !important;
  }
  .social-media-wrapper.contact{
    text-align: center;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  .filter-group{
    margin-right: 0px;
    margin-bottom: 25px;
    margin-top: 0px;
  }
  .shop-filter-item{
    min-width: auto;
    margin-right: 7.5px;
    margin-left: 7.5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 10px;
  }
  .shop-filter-item:first-child{
    margin-left: 0px;
  }
  .shop-filter-item:last-child{
    margin-right: 0px;
  }
}
@media only screen and (max-height: 550px) {
  .chat-body {
    height: 50vh;
  }
}
@media only screen and (max-height: 430px) {
  .chat-body {
    height: 50vh;
  }
  .shop-section.top-section {
    display: none;
  }
}
@media only screen and (min-height: 551px) and (max-height: 800px) {
  .chat-body {
    height: 67vh;
  }
}
@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .chat-body {
    height: 53vh;
  }
  .sidebar-mobile-right {
    overflow: auto;
  }
}
@media only screen and (max-width: 350px) {
  /*.post-date{
      display: none;
    }*/
    .shop-detail{
      padding-left: 10px;
      padding-right: 10px;
    }
    .shop-filter-item{
      min-width: auto;
      margin-right: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
}
@media only screen and (min-width: 700px) and (max-width: 1000px) {
  .social-media-wrapper.contact .social-platform{
    width:90px;
  }
  .shop-post-container{
    margin-left: -3px !important;
  }
}
@media only screen and (min-width: 1001px) and (max-width: 1300px) {
  .social-media-wrapper.contact .social-platform{
    width:90px;
  }
  .shop-post-container{
    margin-left: -3px !important;
  }
}
</style>
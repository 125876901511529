<template>
    <div class="menu-container">
       <vuescroll :ops="ops">
       <div class="menu-wrapper">
        <router-link to="/"><div class="logo"><img src="../assets/tylogo.png"/></div></router-link>
        <!-- <a href="/"><div class="logo"><img src="../assets/tylogo.png"/></div></a> -->
        <!-- <router-link to="/cart">
            <div class="cart">
                <svg style="margin-right:10px;margin-top:-2px" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#3893c1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>
                {{cart.length}} Items in Cart
            </div>
        </router-link> -->
        <div class="form-group has-search">
            <b-form-input v-on:keyup.enter="searchPost()" class="searchbox" type="text" v-model="search" placeholder="Search"></b-form-input>
        </div>
        <div class="search-icon-wrapper">
            <font-awesome-icon v-on:click="searchPost()" class="search-icon" icon="search" />
        </div>
            <div class="main-menus">
                <div v-bind:class="categoryClass" class="menu-item">
                    <div style="float:left;margin-right:15px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
                    </div>
                    <div v-on:click="toggleCategories()" class="menu-txt">หมวดหมู่</div>
                    <font-awesome-icon v-on:click="toggleCategories()" class="list-icon" icon="chevron-down" />
                    <div v-on:click="toggleCategories()" class="clearfix"></div>
                    <ul v-bind:class="categoryClass" class="categorylist">
                        <a href="#" v-on:click="showCategory(menu.id)" v-for="menu in allMenus" :key="menu.id"><li :class="menu.status">{{menu.thtxt}}<div class="eng-menu">{{menu.engtxt}}</div></li></a>
                    </ul>
                    <div class="clearfix"></div>
                </div>
                <!--<div class="menu-item">
                    <div class="menu-txt">ข่าว</div>
                    <div class="clearfix"></div>
                </div>-->
                <div v-if="user == null" v-on:click="closeSideMenu()" class="menu-item">
                    <router-link to="/login">
                        <div style="float:left;margin-right:15px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-in"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path><polyline points="10 17 15 12 10 7"></polyline><line x1="15" y1="12" x2="3" y2="12"></line></svg>
                        </div>
                        <div class="menu-txt">เข้าสู่ระบบ</div>
                        <div class="clearfix"></div>
                    </router-link>
                </div>
                <div v-if="user != null" v-on:click="closeSideMenu()" class="menu-item">
                    <router-link to="/orders">
                        <div style="float:left;margin-right:15px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg>
                        </div>
                        <div class="menu-txt">รายการสั่งซื้อ</div>
                        <div class="clearfix"></div>
                    </router-link>
                </div>
                <!-- <div v-if="user != null" v-on:click="closeSideMenu()" class="menu-item">
                    <router-link to="/myaccount">
                        <div style="float:left;margin-right:15px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                        </div>
                        <div class="menu-txt">บัญชีของฉัน</div>
                        <div class="clearfix"></div>
                    </router-link>
                </div> -->
                <div v-on:click="closeSideMenu()"  class="menu-item">
                    <router-link to="/contact">
                        <div style="float:left;margin-right:15px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                        </div>
                        <div class="menu-txt">ติดต่อเรา</div>
                        <!-- <font-awesome-icon class="list-icon" icon="chevron-down" /> -->
                        <div class="clearfix"></div>
                    </router-link>
                </div>
                <div v-on:click="closeSideMenu()" class="menu-item">
                    <router-link to="/policy">
                        <div style="float:left;margin-right:15px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                        </div>
                        <div class="menu-txt">ข้อกำหนดและเงื่อนไข</div>
                        <!-- <font-awesome-icon class="list-icon" icon="chevron-down" /> -->
                        <div class="clearfix"></div>
                    </router-link>
                </div>
                <div v-if="user != null" v-on:click="signOut()" class="menu-item">
                    <router-link to="/">
                        <div style="float:left;margin-right:15px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                        </div>
                        <div class="menu-txt">ออกจากระบบ</div>
                        <!-- <font-awesome-icon class="list-icon" icon="chevron-down" /> -->
                        <div class="clearfix"></div>
                    </router-link>
                </div>
            </div>
            <div class="social-media-wrapper">
                <div class="social-platform"><a href="https://www.facebook.com/Live-Tycoon-103346034384817/"><img src="../assets/Facebook.png"/></a></div>
                <div class="social-platform"><a href="https://www.instagram.com/livetycoon"><img src="../assets/Instagram.png"/></a></div>
                <div class="social-platform"><a href="#"><img src="../assets/LINE.png"/></a></div>
                <!--<div class="social-platform"><a href="https://www.twitter.com/livetyc00n"><img src="../assets/Twitter.png"/></a></div>-->
                <!-- <div class="social-platform"><img src="../assets/Pinterest.png"/></div> -->
            </div>
            <!--<div class="sponser-wrapper">
                <div class="sponser-item">
                    <img src="../assets/sponserplaceholder.jpg"/>
                </div>
            </div>-->
       </div>
       </vuescroll>
    </div>
</template>

<script>
import { mapGetters,mapActions } from "vuex"
import vuescroll from 'vuescroll';
import firebase from 'firebase';
export default {
    name: "MainMenu",
    data: function () {
        return {
            search:'',
            user: null,
            menuStatus:'unloaded',
            categoryClass: 'closed',
            ops: {
                vuescroll: {},
                scrollPanel: {},
                rail: {},
                bar: {
                    background: '#196A9F',
                    opacity: '0'
                }
            }
        }
    },
    components: {
      vuescroll
    },
    created() {
        /*if(localStorage.getItem('userSetting')){
            var chatSetting = JSON.parse(localStorage.getItem('userSetting'))
            this.chatname = chatSetting.name;
            var userInfo = {id:chatSetting.id};
            this.setUserLogin(userInfo);
        }*/
        var self = this;
        firebase.auth().onAuthStateChanged(function(user) {
          if (user) {
            self.user = user;
          }
        });
    },
    computed: mapGetters(["menuState","allMenus","userInfo","cart"]),
    methods:{
        toggleCategories: function(){
            if (this.categoryClass == 'open') {
                this.categoryClass = 'closed';
            } else {
                this.categoryClass = 'open';
            }
        },
        toggleMobileMenu: function (){
            if (this.menuState == "menu-open") {
                this.setMenuClass("menu-close")
            } else {
                this.setMenuClass("menu-open")
                this.setSideBarMenuClass("hide-shopside-menu")
            }
        },
        showCategory: function(catid){
            this.setPostFilter(catid)
            this.$router.push({ path: `/category/${catid}` })
            this.setMenuClass("menu-close")
        },
        closeSideMenu: function() {
            this.setMenuClass("menu-close")
        },
        searchPost: function() {
            // this.searchPostAllShop(this.search)
            if (this.search.trim() !== ''){
                this.$router.push({ path: `/search/${this.search}` })
                this.search = "";
                this.searchPostAllShop(this.$route.params.keyword)
                this.setMenuClass("menu-close")
            }
        },
        signOut: function() {
            firebase.auth().signOut();
        },
        ...mapActions(["setMenuClass","setSideBarMenuClass","setPostFilter","searchPostAllShop","setUserLogin"])
    }
}
</script>

<style lang="scss">
    .menu-container{
        animation: 0.5s cubic-bezier(.25,1.1,.5,1) 0.1s showLeftMenu;
        animation-fill-mode: both;
        position: fixed;
        width:270px;
        padding: 30px 30px 0px 30px;
        animation-duration: 0.2s;
        animation-delay: 0.2s;
        height:100vh;
        overflow: auto;
        /*background-color: #0E4575;*/
        background-image: -webkit-linear-gradient(top,#fff 0%,#eaeef2 100%);
        background-image: linear-gradient(to bottom,#fff 0%,#eaeef2 100%);
        background-repeat: repeat-x;
        -webkit-box-shadow: 0px 0px 20px 1px rgb(0 0 0 / 20%), inset 0px 0px 2px 1px #fff;
        box-shadow: 0px 0px 20px 1px rgb(0 0 0 / 20%), inset 0px 0px 2px 1px #fff;
        z-index: 999;
    }
    .logo{
        margin-bottom: 40px;
    }
    .logo img{
        width:100%;
    }
    .eng-menu{
        font-size:13px;
        margin-top:5px;
    }
    .has-search{
        margin-bottom: 40px;
    }
    .has-search .searchbox {
        padding-right: 2.375rem;
    }
    .menu-wrapper .search-icon-wrapper {
        position: absolute;
        z-index: 100;
        display: block;
        width: 1.075rem;
        height: 1.075rem;
        line-height: 1.375rem;
        text-align: center;
        // pointer-events: none;
        color: #aaa;
        top:103px;
        right:15px;
        cursor: pointer;
    }
    .main-menus{
        margin-bottom: 40px;
    }
    .menu-item{
        color: #414344;
        border-bottom: 1px solid rgba(0,0,0,0.04);
        padding-top:10px;
        padding-bottom: 10px;
        cursor:pointer;
        font-size:18px;
    }
    .menu-item a{
        color:#414344;
    }
    .menu-item:hover .menu-txt{
        transform: translateX(5px);
    }
    .menu-txt{
        float:left;
        transition: all 0.2s ease;
    }
    .list-icon{
        float:right;
        font-size:12px;
        margin-top:7px;
        transition: all 0.2s ease;
    }
    .menu-item.open .list-icon{
        transform: rotate(180deg); /* Equal to rotateZ(45deg) */
    }
    .social-media-wrapper{
        margin-bottom: 40px;
    }
    .social-platform{
        margin-right: 10px;
        margin-bottom: 5px;
        display: inline-block;
    }
    .social-platform img {
        width: 24px;
    }
    .sponser-wrapper{
        margin-bottom:30px;
    }
    .sponser-wrapper img{
        width:100%;
    }
    ul.categorylist{
        -webkit-padding-start: 25px;
        list-style: none; /* Remove default bullets */
        transition: all 0.3s linear;
    }
    ul.categorylist.closed{
        max-height:0px;
        overflow: hidden;
        margin-bottom: 0px;
    }
    ul.categorylist.open{
        max-height:1000px;
        margin-top: 5px;
        margin-bottom: 10px;
    }
    ul.categorylist li::before {
        content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: #414344; /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
    }
    .categorylist li{
        border-bottom: 1px solid #ccc;
        padding-top:10px;
        padding-bottom: 10px;
        transition: all 0.2s ease;
    }
    .categorylist a{
        color:#414344;
        text-decoration: none;
    }
    .categorylist li:hover{
       margin-left: -10px;
    }
    .categorylist a:hover::before{
       color:#f3155b;
    }
    .categorylist a:last-child li{
        border-bottom: none;
        padding-bottom: 0px;
    }
    .categorylist a li.active{
        color:#f3155b;
    }
    a:hover{
        text-decoration: none;
    }
    @media only screen and (max-width: 1100px) {
        .logo{
            display: none;
        }
        .menu-container{
            padding-top:85px;
        }
        .menu-wrapper .search-icon-wrapper {
            top:7px;
        }
        .menu-wrapper{
            margin-top:40px;
        }
    }
</style>